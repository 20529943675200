import React, { useRef } from 'react';
import PropTypes from "prop-types"

import imgupload from '../../images/buttons/upload.png'


const FileBrowser = ({textlabel, className, handleFile, multiple, disabled}) => {

	const fileinput = useRef(null);

	return(
		<>
			<button
				title={"Upload"}
				disabled={disabled}
				className={className}
				onClick={(e)=>{fileinput.current.click()}}
			>
				{textlabel.length > 0 && <span>{textlabel}</span>}
				<img src={imgupload} alt={"Upload"} />
			</button>
			<input
				disabled={disabled}
				type="file"
				ref={fileinput}
				onChange={handleFile}
				style={{display: 'none'}}
				multiple={multiple}
			/>
		</>
	)
}


FileBrowser.propTypes = {
	textlabel: PropTypes.string,
	className: PropTypes.string,
	multiple: PropTypes.bool,
	disabled: PropTypes.bool,
}


FileBrowser.defaultProps = {
	textlabel: '',
	className: 'iconbutton',
	multiple: false,
	disabled: false
}


export default FileBrowser;

