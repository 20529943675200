import React, {useState} from "react"
import PropTypes from "prop-types"

import FileBrowser from "./filebrowser"

const FileUpload = ({children, defaultfname, identifier, className, disabled, uploadHandler}) => {
	const [fname, setFname] = useState("");
	const [loading, setLoading] = useState(false);
	//const [filekey, setFilekey] = useState("");

	const fileSelectedHandler = (event) => {
		if (event.target.files.length > 0) {
			setLoading(true);
			setFname(event.target.files[0].name);
			let curname = event.target.files[0].name;
			const reader = new FileReader();
			reader.onload = readevent => {
				if (typeof uploadHandler !== 'undefined') {
					const buffer = readevent.target.result;
					const bufferidx = buffer.indexOf(",");
					const typestartidx = buffer.indexOf(":");
					const typeendidx = buffer.indexOf(";");
					uploadHandler(identifier, curname.replace(/\s/g, " ").replace(/[^a-zA-Z0-9_\-\. ]/g, ""), buffer.substring(bufferidx+1), buffer.substring(typestartidx+1, typeendidx));
				}
				//setFilekey(""+Date.now())
				setLoading(false);
			}
			reader.readAsDataURL(event.target.files[0]);
		}
	};


	return <div className={className}>
		<div className={"text-centered font-size-small"}>
			{loading ? <>Loading... </>
			:
				<>
				{(fname !== "" || defaultfname !== "") && <div>{fname === ""? defaultfname: fname}</div>}
				{children && <>{children}</>}
				<FileBrowser disabled={disabled} textlabel={"Choose File"} className={"textbutton"} handleFile={fileSelectedHandler} />
				</>
			}
		</div>
	</div>
}


FileUpload.propTypes = {
	disabled: PropTypes.bool,
	defaultfname: PropTypes.string,
	identifier: PropTypes.string,
	className: PropTypes.string
};

FileUpload.defaultProps = {
	disabled: false,
	defaultfname: "",
	identifier: "",
	className: ""
};


export default FileUpload