import React, { useState } from 'react';
import PropTypes from "prop-types"

import * as styles from "./radio.module.css"

import imgradiochecked from '../../images/controls/radio-checked.png'
import imgradiounchecked from '../../images/controls/radio-unchecked.png'


const ControlRadio = ({basekey, list, defaultvalue, handleChange, disabled}) => {
	const [radiovalue, setRadiovalue] = useState(defaultvalue);

	const onValueChange = (event, displayname) => {
		//console.log(event.target.value);
		setRadiovalue(event.target.value);
		if (typeof handleChange !== "undefined") {
			handleChange(event.target.value);
		}
	}
	return(<>
		{list.map((data, index) => {
			return ((disabled === false || radiovalue===data.code) &&
				<label key={"radiooption"+basekey+index} className={"radiooption "+styles.radiooption}>
					{data.name}
					{disabled === false && <>
						<input  key={"radiooptionbutton"+basekey+index} checked={radiovalue===data.code} name="radioname" type="radio" value={data.code} onChange={e=>onValueChange(e, data.name)} />
						<img className={"radiooptionbox "+styles.radiooptionbox} alt="" src={radiovalue===data.code?imgradiochecked:imgradiounchecked} />
					</>}
				</label>
			)
		})}
	</>)
}


ControlRadio.propTypes = {
	basekey: PropTypes.string,
	defaultvalue: PropTypes.string,
	disabled: PropTypes.bool,
}


ControlRadio.defaultProps = {
	basekey: ``,
	defaultvalue: ``,
	disabled: false,
}


export default ControlRadio;

