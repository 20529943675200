import React, { useState } from 'react';
import PropTypes from "prop-types"


import imgchecked from '../../images/controls/checkbox-checked.png'
import imgunchecked from '../../images/controls/checkbox-unchecked.png'


const ControlCheckbox = ({basekey, defaultvalue, handleChange, disabled}) => {
	// null, blank as 0
	const [checkvalue, setCheckvalue] = useState(defaultvalue?(defaultvalue===""?"0":defaultvalue+""):"0");

	const onValueChange = (event) => {
		var newval = 1;
		if (checkvalue ==="1") {
			newval = 0;
		}
		setCheckvalue(newval+"");
		if (typeof handleChange !== "undefined") {
			handleChange(newval);
		}
	}
	const displaytext = (checkvalue!=="0"?"Yes":"No")
	return(<>
		{disabled === false ? <button key={"checkboxbutton"+basekey} className={'iconbutton iconbutton-medium'} onClick={onValueChange} title={displaytext} >
			<img alt={displaytext} src={checkvalue!=="0"?imgchecked:imgunchecked} />
		</button>
		:
		<>
			{displaytext}
		</>}
	</>)
}


ControlCheckbox.propTypes = {
	basekey: PropTypes.string,
	defaultvalue: PropTypes.string,
	disabled: PropTypes.bool,
}


ControlCheckbox.defaultProps = {
	basekey: ``,
	defaultvalue: ``,
	disabled: false,
}


export default ControlCheckbox;

