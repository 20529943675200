import React, { useState, useMemo} from 'react';
import PropTypes from "prop-types"

import ControlSelect from "../../controls/select";

import imgaddfilter from "../../../images/buttons/add_filter.png"
import imgclose from "../../../images/buttons/close.png"

import * as styles from "../style.module.css"

const formatTools = require("../../../../lib/formatTools");

const FilterField = ({basekey, label, field, type, filters, defaultvalue, handleSubmit, handleRemove, disabled}) => {
	const [inputdirty, setInputdirty] = useState(false);
	const [currentvalue, setCurrentvalue] = useState(defaultvalue);
	const [currentoperator, setCurrentoperator] = useState("=");
	const [controlkey, setControlkey] = useState(basekey);

	//onKeyDown={this._handleKeyDown}
	const controloptions = useMemo(() => {
		var newoptions = [];
		if (type === "integer"  || type === "numeric" || type === "currency") {
			newoptions = [
				{key:"operatorlt"+controlkey, code:"<", name:"<"},
				{key:"operatorlte"+controlkey, code:"<=", name:"<="},
				{key:"operatoreq"+controlkey, code:"=", name:"="},
				{key:"operatorgte"+controlkey, code:">=", name:">="},
				{key:"operatorgt"+controlkey, code:">", name:">"}
			];
		} else if (type === "date" || type === "datetime") {
			newoptions = [
				{key:"operatorgt"+controlkey, code:">", name:"After"},
				{key:"operatorgte"+controlkey, code:">=", name:"From"},
				{key:"operatoreq"+controlkey, code:"=", name:"On"},
				{key:"operatorlte"+controlkey, code:"<=", name:"Until"},
				{key:"operatorlt"+controlkey, code:"<", name:"Before"}
			];
		} else {
			newoptions = [
					{key:"operatorcontains"+controlkey, code:"=", name:"Contains"},
					{key:"operatorequal"+controlkey, code:"exact", name:"Exactly"},
					{key:"operatorbegins"+controlkey, code:"begin", name:"Begins w/"},
					{key:"operatorends"+controlkey, code:"end", name:"Ends w/"},
					{key:"operatornot"+controlkey, code:"not", name:"Is not"},
					{key:"operatornothave"+controlkey, code:"nothave", name:"Doesn't contain"}
				];
		}
		return newoptions;
	}, [type, controlkey]);


	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			setFilterValue(null);
		}
	}

	const handleInputChange = (e) => {
		if (e) {
			e.preventDefault();
		}
		handleValueChange(e.target.value);
	}

	const handleValueChange = (newvalue) => {
		if (type === "checkbox") {
			var displaytext = label;
			if (parseInt(newvalue, 10) !== 0) {
				displaytext = "Is "+label;
			} else {
				displaytext = "Not "+label;
			}
			submitData(displaytext, field, currentoperator, newvalue);
		} else {
			setCurrentvalue(newvalue);
			if (newvalue.length < 1) {
				setInputdirty(false);
			} else {
				setInputdirty(true);
			}
		}
	}

	const defaultHandleRemove = (e, removeidx) => {
		if (e) {
			e.preventDefault();
		}
		if (typeof handleRemove !== "undefined") {
			handleRemove(removeidx);
		}
	}

	function setFilterValue(e)
	{
		if (e) {
			e.preventDefault();
		}
		var newvalue = formatTools.trimstr(currentvalue);
		var newoperation = currentoperator;
		var displaytext = label;
		const curoptions = controloptions;
		var tmpidx = 0;
		while (tmpidx < curoptions.length) {
			if (curoptions[tmpidx].code===currentoperator) {
				displaytext = label + " " +  curoptions[tmpidx].name.toLowerCase() + " ";
			}
			tmpidx++;
		}
		if (newvalue === "") {
			return;
		}
		if (type === "integer" || type === "numeric" || type === "currency") {
			if (type === "integer") {
				newvalue = (""+formatTools.filterInteger(newvalue));
			} else {
				newvalue = (""+formatTools.filterNumeric(newvalue));
			}
			if (newvalue.length < 1) {
				return;
			}
			displaytext = displaytext + "'"+newvalue+"'";
		} else if (type === "date" || type === "datetime") {
			displaytext = displaytext + "'"+formatTools.datestr(newvalue)+"'";
			if (currentoperator === "=") {
				newoperation = " like ";
				newvalue = newvalue+"%";
			} else if (currentoperator === ">") {
				newvalue = newvalue+" 23:59:59";
			} else if (currentoperator === "<=") {
				newvalue = newvalue+" 23:59:59";
			}
		} else if (type === "textbox" || type === "text" || type === "textarea") {
			displaytext = displaytext + "'"+newvalue+"'";
			if (currentoperator === "not") {
				newoperation = " not like ";
			} else if (currentoperator === "nothave") {
				newoperation = " not like ";
				newvalue = "%"+newvalue+"%";
			} else if (currentoperator === "exact") {
				newoperation = " like ";
			} else if (currentoperator === "=") {
				newoperation = " like ";
				newvalue = "%"+newvalue+"%";
			} else if (currentoperator === "begin") {
				newoperation = " like ";
				newvalue = newvalue+"%";
			} else if (currentoperator === "end") {
				newoperation = " like ";
				newvalue = "%"+newvalue;
			}
		} else {
			displaytext = label + " = '"+newvalue+"'";
			newoperation = "=";
		}
		submitData(displaytext, field, newoperation, newvalue);
	}

	function setBlankChecking(e,isblank)
	{
		if (e) {
			e.preventDefault();
		}
		var displaytext = label;
		if(isblank) {
			displaytext = displaytext + " is blank";
		} else {
			displaytext = displaytext + " not blank";
		}
		submitData(displaytext, field, isblank? "=" :"<>", "");
	}

	function submitData(displaytext, newfield, newoperation, newvalue)
	{
		handleSubmit(displaytext, newfield, newoperation, newvalue);
		setCurrentvalue("");
		setCurrentoperator("=");
		setInputdirty(false);
		setControlkey(basekey+Date.now());
	}

	function generateFieldAddButton()
	{
		return <>
			<button
				title={"Add"}
				className={"iconbutton iconbutton-medium"}
				disabled={disabled || inputdirty === false}
				onClick={setFilterValue}
			>
				<img src={imgaddfilter} alt="Add" />
			</button>
		</>
	}

	function generateFieldBlankButton()
	{
		return <>
			<button
				title={"Is blank"}
				className={styles.searchtablefilterfieldinputbutton+" textbutton"}
				disabled={disabled}
				onClick={(e)=>{setBlankChecking(e, true)}}
			>
				Is blank
			</button>
			<button
				title={"Not blank"}
				className={styles.searchtablefilterfieldinputbutton+" textbutton"}
				disabled={disabled}
				onClick={(e)=>{setBlankChecking(e, false)}}
			>
				Not blank
			</button>
		</>
	}

	function generateFieldInput()
	{
		if (type === "checkbox") {
			return <>
					<button
						title={"Yes"}
						className={styles.searchtablefilterfieldinputbutton+" textbutton"}
						disabled={disabled}
						onClick={(e)=>{handleValueChange("1")}}
					>
						Yes
					</button>
					<button
						title={"No"}
						className={styles.searchtablefilterfieldinputbutton+" textbutton"}
						disabled={disabled}
						onClick={(e)=>{handleValueChange("0")}}
					>
						No
					</button>
				</>
		} else if (type === "image") {
			return <>
					{generateFieldBlankButton()}
				</>
		}
		const newoptions = controloptions;
		//const newoptions = [];
		var newtype = null;
		if (type === "date" || type === "datetime") {
			newtype="date";
		}
		return <>
			{generateFieldBlankButton()}
			{newoptions.length > 0 && <>
				<br/><br/>
				<div className={styles.searchtablefilterfieldinputoptions}>
					<ControlSelect
						key={"textoperator"+controlkey}
						list={newoptions}
						defaultvalue={currentoperator}
						handleChange={setCurrentoperator}
						disabled={disabled}
					/>
				</div>
				<input
					type={newtype}
					disabled={disabled}
					className={styles.searchtablefilterfieldinputbox+" font-size-small"}
					name={field}
					value={currentvalue}
					onKeyDown={handleKeyDown}
					onChange={handleInputChange}
					onBlur={handleInputChange}
				/>
				{generateFieldAddButton()}
			</>}
		</>
	}

	function generateField()
	{
		if (type !== "none") {
			return <div className={styles.searchtablefilterfield}>
						<span className={styles.searchtablefilterfieldlabel+" font-style-bold"}>{label+":"}</span>
						<div className={styles.searchtablefilterfieldinput}>
							{generateFieldInput()}
						</div>
						<div className={styles.searchtablefilterfieldvaluelist}>
							{filters.map((filteritem, filteridx)=>{
									if (field !== filteritem.field) {
										return <></>
									}
									return <button key={basekey+"filter"+filteridx} className={"textbutton"} onClick={(e)=>{defaultHandleRemove(e, filteridx)}}>
											<span>{filteritem.display.replace(label+" ","")}</span>
											<img src={imgclose} alt="x" />
										</button>
							})}
						</div>
					</div>
		}
		return <></>

	}

	return(<>{generateField()}</>)
}


FilterField.propTypes = {
	basekey: PropTypes.string,
	defaultvalue: PropTypes.string,
	disabled: PropTypes.bool,
	filters: PropTypes.array
}


FilterField.defaultProps = {
	basekey: ``,
	defaultvalue: ``,
	disabled: false,
	filters: []
}


export default FilterField;

