
import React from "react"
import PropTypes from "prop-types"

import FormField from "./formfield";

import * as styles from "../style.module.css"

import imgdeletehollow from "../../../images/buttons/delete_hollow.png"
import imgdelete from "../../../images/buttons/delete.png"

import imgdeletemobilehollow from "../../../images/buttons/deletecontrast_hollow.png"
import imgdeletemobile from "../../../images/buttons/deletecontrast.png"


const SubForm = ({pkid, token, forminfo, forminputlist, formfooterlist, formvariables, formidx, initializing, disabled, handleInputChange, handleAddRow, customFieldInfo, showPicker, removePicker, viewmode}) => {

	const addRowClick = (e) => {
		if (e) {
			e.preventDefault();
		}
		handleAddRow(formidx);
	}

	const deleteRowClick = (rowidx, deletefieldidx) => {
		const fieldid = formidx+"_"+rowidx+"_"+deletefieldidx;
		const newvalue = (forminputlist[rowidx][deletefieldidx].value+"" === "0"?1:0);
		handleInputChange(newvalue, "integer", fieldid);
	}

	const headergenerator = () => {
		return forminfo.fieldlist.map((entry, idx)=>{
			if (entry.input === "hidden") {
				return <></>
			}
			return <td key={"subform"+entry.table+formidx+"header"+idx} className={styles.searchtablesortheader}>{entry.label}</td>
		})
	};

	const rowdatagenerator = (rowpkid, fieldlist, rowidx, removed) => {
		if (fieldlist.length > 0) {
			const labelclass=styles.searchtablecelllabel+" font-style-italic font-style-bold font-size-small";

			var tmpfieldidx = 0;
			var hidemobileidxlist = [];
			while (tmpfieldidx < fieldlist.length) {
				if (fieldlist[tmpfieldidx].input !== "hidden" && fieldlist[tmpfieldidx].mode === "readonly") {
					hidemobileidxlist.push(tmpfieldidx);
				}
				tmpfieldidx++;
			}

			return fieldlist.map((fielddetails, idx)=> {
				var tmpclass = "";
				var finalentrydata = fielddetails;
				if (typeof customFieldInfo !== "undefined") {
					finalentrydata = customFieldInfo(fieldlist, idx);
				}

				if (finalentrydata.input === "hidden") {
					return <></>
				}
				const keyname = forminfo.table + "subformrow"+rowidx+"col"+idx;

				if (rowidx==="subtotal") {
					var subtotalid = "";
					if (finalentrydata.hasOwnProperty("subtotalid")) {
						subtotalid = finalentrydata.subtotalid;
					}
					if (subtotalid==="") {
						return <td key={keyname}>&nbsp;</td>
					}
					var tmpdetails = JSON.parse(JSON.stringify(finalentrydata));
					tmpdetails.value = "";
					if (formvariables.hasOwnProperty(subtotalid)) {
						tmpdetails.value = formvariables[subtotalid]
					}
					tmpdetails.mode = "readonly";
					return <td key={keyname}>
								<span className={labelclass}>{finalentrydata.label+":"}</span>
								<FormField
									fieldid={formidx+"_"+rowidx+"_"+idx}
									fielddetails={tmpdetails}
									disabled={disabled}
									viewmode={viewmode||removed}
									displaymode={"subform"}
									handleChange={handleInputChange}
									showPicker={showPicker}
									removePicker={removePicker}
									token={token}
									pkid={rowpkid}
								/>
							</td>
				}
				if (hidemobileidxlist.includes(idx)) {
					tmpclass = styles.searchtablecellhiddenmobiletitle;
				}
				return <td key={keyname} className={tmpclass}>
							<span className={labelclass}>{finalentrydata.label+":"}</span>
							<FormField
								fieldid={formidx+"_"+rowidx+"_"+idx}
								fielddetails={finalentrydata}
								disabled={disabled}
								viewmode={viewmode||removed}
								displaymode={"subform"}
								handleChange={handleInputChange}
								showPicker={showPicker}
								removePicker={removePicker}
								token={token}
								pkid={rowpkid}
							/>
						</td>
			});
		}
		return <></>
	};

	if (!forminputlist) {
		return <></>
	}

	const allowdel = (forminfo.hasOwnProperty("allowdel")?forminfo.allowdel:true);
	const allowadd = forminfo.minrow!==forminfo.maxrow;
	var hassubtotal = false;
	var tmpidx = 0;
	const deletefieldidx = 0;
	const pkididx = 1;

	if (forminputlist.length > 0) {
		while (tmpidx < forminputlist[0].length) {
			if (forminputlist[0][tmpidx].hasOwnProperty("subtotalid")) {
				if (forminputlist[0][tmpidx].subtotalid.length > 0) {
					hassubtotal = true;
				}
			}
			tmpidx++;
		}
	}

	return (<div className={styles.editsubformholder}>
			<table className={styles.searchtable}>
				{tmpidx > 0 && <thead>
					<tr>
						<td colSpan={1000} style={{"textAlign":"left"}}>
							<h3 className={styles.editsubformholdertitle}>{forminfo.label}</h3>
						</td>
					</tr>
					<tr className={styles.searchtableheader}>
						<td>#</td>
						{headergenerator()}
					</tr>
				</thead>}
				{initializing === false &&
					<tbody>
						{forminputlist.map((rowdata, rowidx)=>{
							const tmppkid = parseInt(rowdata[pkididx].value, 10);
							const removed = rowdata[deletefieldidx].value+"" !== "0";
							// idx 1 is row pkid
							const candelete = (allowdel && viewmode === false) || rowdata[1].value+"" === "0";

							var mobilerowdisplaytext = "";
							var tmpmobilefieldidx = 0;
							var tmpfieldidx = 0;
							var rowdatamap = [];
							while (tmpfieldidx < rowdata.length) {
								if (rowdata[tmpfieldidx].input !== "hidden") {
									rowdatamap.push(tmpfieldidx);
								}
								tmpfieldidx++;
							}
							while(tmpmobilefieldidx < forminfo.mobilerowtitlefieldidx) {
								tmpfieldidx = rowdatamap[tmpmobilefieldidx];
								// TODO: formatting
								if (rowdata[tmpfieldidx].mode === "readonly") {
									mobilerowdisplaytext = " " + rowdata[tmpfieldidx].value;
								}
								tmpmobilefieldidx++;
							}
							mobilerowdisplaytext = (rowidx+1)+")"+mobilerowdisplaytext;

							return <tr key={"subform"+forminfo.table+"list"+rowidx} className={removed?styles.editsubformrowremoved:""}>
								<td className={styles.searchtablecellright+" "+styles.searchtablerownum}>
									<span className="fullblock-desktop-only">
										{candelete && <>
											<button title={"Delete"} onClick={(e)=>{e.preventDefault(); deleteRowClick(rowidx, deletefieldidx);}} className="iconbutton">
												<img src={removed===false?imgdeletehollow:imgdelete} alt="Delete" />
											</button>
										</>}
										{rowidx+1}
									</span>
									<span className="fullblock-mobile-only">
										{candelete && <>
											<button title={"Delete"} onClick={(e)=>{e.preventDefault(); deleteRowClick(rowidx, deletefieldidx);}} className="iconbutton iconbutton-medium">
												<img src={removed===false?imgdeletemobilehollow:imgdeletemobile} alt="Delete" />
											</button>
										</>}

										<>
											{mobilerowdisplaytext}
										</>
									</span>
								</td>
								{rowdatagenerator(tmppkid, rowdata, rowidx, removed)}
							</tr>
						})}
						{((allowadd && viewmode === false) && (typeof handleAddRow !== "undefined")) && <tr className={styles.editsubformrowaction} key={"subform"+forminfo.table+"listaddrow"}>
								<td className={styles.searchtablerowheader}>
									<span className="fullblock-mobile-only">{forminfo.label} Actions</span>
								</td>
								<td colSpan={1000} className={styles.searchtablecellright}>
									<button title={"Add "+forminfo.label} className="textbutton" onClick={addRowClick}>
										Add {forminfo.label}
									</button>
								</td>
						</tr>}
						{hassubtotal === true && <tr  className={styles.editsubformrowtotals} key={"subform"+forminfo.table+"listsubtotal"}>
								<td className={styles.searchtablerowheader}>
									<span className="fullblock-desktop-only">
										&nbsp;
									</span>
									<span className="fullblock-mobile-only">
										Sub Totals
									</span>
								</td>
								{rowdatagenerator(-1, forminputlist[0], "subtotal", false)}
						</tr>}
						{formfooterlist.map((footerrow, footeridx)=>{
							var finalentrydata = footerrow;
							if (typeof customFieldInfo !== "undefined") {
								finalentrydata = customFieldInfo(formfooterlist, footeridx);
							}

							return <tr  className={styles.editsubformrowfooter} key={"subform"+forminfo.table+"footerrow"+footeridx}>
								<td colSpan={1000} className={styles.searchtablecellright}>
									<FormField
										fieldid={"subtotal_"+formidx+"_"+footeridx}
										fielddetails={finalentrydata}
										disabled={disabled}
										viewmode={viewmode}
										displaymode={"footer"}
										handleChange={handleInputChange}
										showPicker={showPicker}
										removePicker={removePicker}
										token={token}
										pkid={pkid}
									/>
								</td>
							</tr>
						})}
					</tbody>
				}
			</table>
		</div>)
}


SubForm.propTypes = {
	initializing: PropTypes.bool,
	disabled: PropTypes.bool,
	viewmode: PropTypes.bool
}


SubForm.defaultProps = {
	initializing: false,
	disabled: false,
	viewmode: false
}

export default SubForm
