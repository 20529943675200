import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types"



import imgloading from "../../../images/loading.svg"
import * as styles from "../../controls/upload.module.css"

const webappsAPI = require("../../../../lib/requestWebapps");



const WebappDLImage = ({token, srcurl, imageonly, className}) => {
	// For images that need to be fetched securely
	const [loading, setLoading] = useState(true);
	const [dlimgsrc, setDLImgsrc] = useState("");

	useEffect(()=> {
		let isMounted = true; // Prevent leaks
		if (isMounted) {
			//(s|a)[host/]webappsiamnet/12/testtable_file/1
			var tmpstr = "";
			var pkid = 0;

			// pkid
			var tmpidx = srcurl.lastIndexOf("/");
			if (tmpidx <= 0) {
				setLoading(false);
				return;
			}
			pkid = parseInt(srcurl.substring(tmpidx+1), 10);
			tmpstr = srcurl.substring(0, tmpidx);

			// db field
			tmpidx = tmpstr.lastIndexOf("/");
			if (tmpidx <= 0) {
				setLoading(false);
				return;
			}
			tmpstr = tmpstr.substring(tmpidx+1);

			webappsAPI.downloadFile(webappsAPI.getTablefromDBfield(tmpstr), tmpstr, pkid, token).then(response => {
				if (response.status==="OK") {
					if (response.fname.length > 0) {
						tmpstr = "png";
						tmpidx = response.fname.lastIndexOf(".");
						if (tmpidx > 0) {
							tmpstr = response.fname.substring(tmpidx+1).toLowerCase();
							if (tmpstr === "jpg") {
								tmpstr = "jpeg";
							}
						}
						setDLImgsrc("data:image/"+tmpstr+";base64,"+response.base64);
					}
				}
				setLoading(false);
			});
		}
		return () => { isMounted = false };
	}, [srcurl, token])


	const imageZoom = (imgurl) => {
		window.open(imgurl,"_blank");
	};

	// None image only copied from imageuploader
	return <>{imageonly? <>
		{loading?
			<img src={imgloading} alt={"Loading..."} className={className} />
		:
			<img src={dlimgsrc} alt={""} className={className} />
		}
		</>
	:
		<div className={styles.uploadimageholder+" text-centered"}>
			{loading ?
				<img src={imgloading} alt={"Loading..."} />
			: <>
				{dlimgsrc!=="" ?<>
					<div className={"text-centered font-style-italic font-size-small"}>(Click to Zoom)</div>
					<img src={dlimgsrc} alt={""}  onKeyDown={(e)=>{/* Prevents warning */}} onClick={(e)=>{imageZoom(dlimgsrc);}} />
				</>:<>
					Error
				</>}
			</>}
		</div>
	}</>

}


WebappDLImage.propTypes = {
	imageonly: PropTypes.bool,
	className: PropTypes.string
}


WebappDLImage.defaultProps = {
	imageonly: false,
	className: ""
}
export default WebappDLImage
