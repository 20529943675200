
import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"

import ControlSelect from "../controls/select";
import ControlFloatTextbox from "../controls/floattextbox";
import WebappDLImage from "./controls/webappdlimage";

import FilterField from "./controls/filterfield";

import * as tablescroll from "../../styles/module/tablescroll.module.css"
import * as styles from "./style.module.css"

import imgboxholder from "../../images/boxholder.png"
import imgnavprev from "../../images/buttons/nav_prev.png"
import imgnavnext from "../../images/buttons/nav_next.png"


import imgadddoc from "../../images/buttons/add_doc.png"
import imgfilter from "../../images/buttons/filter.png"
import imgfilterclear from "../../images/buttons/filter_clear.png"
import imgrefresh from "../../images/buttons/refresh.png"
import imgclose from "../../images/buttons/close.png"
import imgcheck from "../../images/buttons/done.png"

const FULLCOLSPANVALUE=1000;
const UNSORTABLELIST=["image"];


const formatTools = require("../../../lib/formatTools");
const webappsAPI = require("../../../lib/requestWebapps");
/*
const fieldList = [
	{"label":"", "dbfield": "", "type": "", "filtertype": ""}
];
*/
const WebappSearch = ({pageaction, pagetitle, mobilerowtitlefield, disabled, token, entity, userfilter, userparam, fieldList, allowadd, searchHeaderGenerator, searchRowGenerator, searchRowClick, newDocumentClick, customShowField, pickedcount, pickHandler, initsearchstate}) => {
	const [loading, setLoading] = useState(false);
	const [listdata, setListdata] = useState([]);
	const [paginationitems, setPaginationitems] = useState(50);
	const [pageidx, setPageidx] = useState(initsearchstate.hasOwnProperty("pageidx")?initsearchstate.pageidx:0);
	const [totalrecord, setTotalrecord] = useState(0);
	const [hasnextpage, setHasnextpage] = useState(false);
	const [editpageidx, setEditpageidx] = useState(false);
	const [showfilter, setShowfilter] = useState(false);
	const [filters, setFilters] = useState(initsearchstate.hasOwnProperty("filter")?initsearchstate.filter:[]);
	const [orderbyfield, setOrderbyfield] = useState(initsearchstate.hasOwnProperty("orderbyfield")?initsearchstate.orderbyfield:"");
	const [orderbymode, setOrderbymode] = useState(initsearchstate.hasOwnProperty("orderbymode")?initsearchstate.orderbymode:"desc");


	const loadAPIParam = useCallback( () => {
		var tmpfilterlist = JSON.parse(JSON.stringify(userfilter));
		var apiparam = JSON.parse(JSON.stringify(userparam));
		if (apiparam.hasOwnProperty("orderby")) {
			delete apiparam.orderby;
		}

		if (filters.length > 0 || tmpfilterlist.length > 0) {
			var idx = 0;
			while (idx < filters.length) {
				tmpfilterlist.push ({
					field: filters[idx].field,
					operation: filters[idx].operation,
					value: filters[idx].value
				});
				idx++;
			}
			apiparam.filters = [tmpfilterlist];
		} else {
			apiparam.filters = [];
		}
		return apiparam;
	}, [filters, userfilter, userparam]);

	const loadDBFieldlist = useCallback( () => {
		var dbfieldlist = [];
		var idx = 0;
		while (idx < fieldList.length) {
			dbfieldlist.push(fieldList[idx].dbfield);
			idx++;
		}
		return dbfieldlist;
	}, [fieldList]);

	const loadTotalCount = useCallback( (e) => {
		if (e) {
			e.preventDefault();
		}
		if (token !== "") {
			var apiparam = loadAPIParam();

			// Necessary to handle the joins
			apiparam.dbfieldlist = loadDBFieldlist();
			apiparam.countfield=entity.toUpperCase()+"."+entity.toLowerCase()+"_id";

			webappsAPI.loadData(entity, apiparam, token).then(response => {
				if (response.status === "OK") {
					setTotalrecord(response.count);
					const maxpage = Math.ceil(response.count/paginationitems);
					if (pageidx > maxpage) {
						setPageidx(maxpage-1);
					}
				}
			});
		}
	}, [token, entity, paginationitems, pageidx, loadAPIParam, loadDBFieldlist])

	const loadList = useCallback( (e) => {
		if (e) {
			e.preventDefault();
		}
		if (token !== "") {
			setLoading(true);
			var apiparam = loadAPIParam();
			var orderbycond = "";
			if (orderbyfield.length > 0) {
				orderbycond = orderbyfield;
				if (orderbymode.length > 0) {
					orderbycond = orderbycond + " " + orderbymode;
				}
				apiparam.orderby = orderbycond;
			} else if (userparam.hasOwnProperty("orderby")) {
				if (userparam.orderby.length > 0) {
					const desccheck = userparam.orderby.indexOf(" ");
					if (desccheck > 0) {
						setOrderbyfield(userparam.orderby.substring(0, desccheck));
						setOrderbymode(userparam.orderby.substring(desccheck+1));
					} else {
						setOrderbyfield(userparam.orderby);
						setOrderbymode("");
					}
					// Will rerun because of change
					return;
				}
			} else {
				var tmpfieldidx = 0;
				while (tmpfieldidx < fieldList.length) {
					if (!UNSORTABLELIST.includes(fieldList[tmpfieldidx].type)) {
						setOrderbyfield(fieldList[tmpfieldidx].dbfield);
						setOrderbymode("");
						return;
					}
					tmpfieldidx++;
				}
			}

			apiparam.offset = pageidx*paginationitems;
			apiparam.count = paginationitems;
			apiparam.dbfieldlist = loadDBFieldlist();

			webappsAPI.loadData(entity, apiparam, token).then(response => {
				if (response.status === "OK") {
					setListdata(response.data);
					setHasnextpage(response.hasmore);
				} else {
					setHasnextpage(false);
					setPageidx(0);
					setListdata([]);
					setTotalrecord(0);
					//setDumpdata(JSON.stringify(response));
				}
				setLoading(false);
			});
		}
	}, [token, fieldList, entity, pageidx, paginationitems, orderbyfield, orderbymode, userparam, loadAPIParam, loadDBFieldlist])

	useEffect(() => {
		if (token !== "") {
			loadList(null);
		}
	}, [token, loadList])


	useEffect(() => {
		if (token !== "") {
			loadTotalCount(null);
		}
	}, [token, loadTotalCount])

	function updatePageidx(newvalstr)
	{
		var newval = parseInt(newvalstr, 10);
		if (!isNaN(newval)) {
			const maxpage = Math.ceil(totalrecord/paginationitems);
			if (newval < 1) {
				newval = 1;
			} else if (newval > maxpage) {
				newval = maxpage;
			}
			setPageidx(newval-1);
		}
		setEditpageidx(false);
	}

	function updatePaginationitems(newvalstr)
	{
		const newval = parseInt(newvalstr, 10);
		const newnumpage = Math.ceil(totalrecord/newval);
		if (newnumpage <= pageidx) {
			setPageidx(newnumpage-1);
		}
		setPaginationitems(newval);
	}

	function refreshData()
	{
		loadList(null);
		loadTotalCount(null);
	}

	function clearFilters(e)
	{
		if (e) {
			e.preventDefault();
		}
		setFilters([]);
	}

	function toggleSeachfield(e,fieldidx)
	{
		if (e) {
			e.preventDefault();
		}
		if (orderbyfield === fieldList[fieldidx].dbfield) {
			if (orderbymode === "") {
				setOrderbymode("desc");
			} else {
				setOrderbymode("");
			}
		} else if (!UNSORTABLELIST.includes(fieldList[fieldidx].type)) {
			setOrderbyfield(fieldList[fieldidx].dbfield);
			// TODO: UI Option?  For now hardcode default toggle of date to desc
			if (fieldList[fieldidx].type === "date" || fieldList[fieldidx].type === "datetime") {
				setOrderbymode("desc");
			} else {
				setOrderbymode("");
			}
		}

	}

	function defaultSearchHeaderGenerator()
	{
		if (typeof searchHeaderGenerator !== "undefined") {
			return searchHeaderGenerator();
		} else if (fieldList.length > 0) {

			return fieldList.map((field, idx)=> {
				if (field.type==="hidden") return <></>
				return <td className={field.type==="image"?"":styles.searchtablesortheader} key={entity+"_searchheader_"+idx} onKeyDown={(e)=>{/* Prevents warning */}} onClick={(e)=>{toggleSeachfield(e, idx)}}>{field.label}{
					field.dbfield===orderbyfield?<>
						{orderbymode===""?<>&uarr;</>: <>&darr;</>}
					</>
					: <></>
				}</td>
			});
		}
		return <></>
	}

	function defaultPickhandler(e, status)
	{
		if (e) {
			e.preventDefault()
		}
		if (typeof pickHandler !== "undefined") {
			pickHandler(status);
		}
	}

	function buildSearchState()
	{
		return {
			"pageidx": pageidx,
			"filter": filters,
			"orderbyfield": orderbyfield,
			"orderbymode": orderbymode,
		};
	}

	function defaultNewDocumentClick(e)
	{
		if (typeof newDocumentClick !== "undefined") {
			newDocumentClick(buildSearchState());
		}
	}

	function defaultSearchRowClick(e, rowdata)
	{
		if (typeof searchRowClick !== "undefined") {
			searchRowClick(e, rowdata, buildSearchState());
		}
	}

	function getMobileTitleRow(entry, idx)
	{
		var output = "";
		if (mobilerowtitlefield.length) {
			var titleidx = 0;
			var fieldidx = 0;
			var tmpstr = "";
			var fieldvalue = "";
			var field = {};

			while (titleidx < mobilerowtitlefield.length) {
				tmpstr = "";
				fieldvalue = entry[mobilerowtitlefield[titleidx]];
				fieldidx = 0;
				field = {};
				while (fieldidx < fieldList.length) {
					if (fieldList[fieldidx].dbfield === mobilerowtitlefield[titleidx]) {
						field = fieldList[fieldidx];
						break;
					}
					fieldidx++;
				}

				if (field.hasOwnProperty("type")) {
					if (field.type === "checkbox") {
						tmpstr = fieldvalue!==0?"Y":"N";
					} else if (field.type === "image") {
						tmpstr = "";
					} else if (field.type === "integer" || field.type === "numeric" || field.type === "currency") {
						if (field.type==="integer")
							tmpstr = formatTools.integerstr(fieldvalue);
						else if (field.type ==="currency")
							tmpstr = formatTools.currencystr(fieldvalue);
						else
							tmpstr = formatTools.numericstr(fieldvalue, 4);
					} else if (field.type === "date" || field.type === "datetime") {
						if (field.type==="date")
							tmpstr = formatTools.datestr(fieldvalue);
						else
							tmpstr = formatTools.datestr(fieldvalue) + " " +formatTools.timestr(fieldvalue);
					} else if (field.type==="hidden") {
						tmpstr = "";
					} else {
						tmpstr = formatTools.utf8str(fieldvalue);
					}
				} // type
				if (tmpstr.length > 0) {
					output = output + " " + tmpstr;
				}
				titleidx++;
			}

		}
		if (output.length < 1) {
			output = (idx+pageidx*paginationitems)+1+")";
		} else {
			output = output.substring(1);
		}
		return output;
	}

	function defaultSearchRowGenerator(rowdata, rowidx)
	{
		if (typeof searchRowGenerator !== "undefined") {
			return searchRowGenerator(rowdata, entity + "searchrow"+rowidx);
		} else if (fieldList.length > 0) {
			const labelclass=styles.searchtablecelllabel+" font-style-italic font-style-bold font-size-small";
			return fieldList.map((field, idx)=> {
				var tdclass = "";
				var titleidx = 0;
				while (titleidx < mobilerowtitlefield.length) {
					if (mobilerowtitlefield[titleidx] === field.dbfield) {
						tdclass = styles.searchtablecellhiddenmobiletitle+" ";
						break;
					}
					titleidx++;
				}

				const keyname = entity + "searchrow"+rowidx+"col"+idx;
				const fieldvalue = (rowdata.hasOwnProperty(field.dbfield)?rowdata[field.dbfield]:"");
				if (field.type === "checkbox") {
					return <td key={keyname} className={tdclass+"text-centered"}>
							<span className={labelclass}>{field.label+":"}</span>
							{fieldvalue!==0?"Y":"N"}
						</td>
				} else if (field.type === "image") {
					var prefix = "h";
					if (fieldvalue !== null) {
						if (fieldvalue !== "") {
							prefix = fieldvalue.substring(0,1);
						}
					}
					return <td key={keyname} className={tdclass+"text-centered"}>
							<span className={labelclass}>{field.label+":"}</span>
							{prefix === "h" ?
								<img src={fieldvalue} alt="" className={styles.searchtablecelldataimage} />
							:
								<WebappDLImage
									className={styles.searchtablecelldataimage}
									imageonly={true}
									token={token}
									srcurl={fieldvalue}
								/>
							}
						</td>
				} else if (field.type === "custom" && typeof customShowField !== "undefined") {
					return <td key={keyname} className={tdclass+"text-centered"}>
							<span className={labelclass}>{field.label+":"}</span>
							{
								customShowField(field, idx, rowdata, styles)
							}
						</td>
				} else if (field.type === "integer" || field.type === "numeric" || field.type === "currency") {
					return <td key={keyname} className={tdclass+styles.searchtablecellright}>
							<span className={labelclass}>{field.label+":"}</span>
							{field.type==="integer"?
								formatTools.integerstr(fieldvalue)
							:(field.type ==="currency"?
								formatTools.currencystr(fieldvalue)
							:
								formatTools.numericstr(fieldvalue, 4)
							)}
						</td>
				} else if (field.type === "date" || field.type === "datetime") {
					return <td key={keyname} className={tdclass+styles.searchtablecellright}>
							<span className={labelclass}>{field.label+":"}</span>
							{field.type==="date"?
								formatTools.datestr(fieldvalue)
							:<>
								{formatTools.datestr(fieldvalue)}
								<br/>
								{formatTools.timestr(fieldvalue)}
							</>
							}
						</td>
				} else if (field.type==="hidden") {
					return <></>
				} else {
					return <td  key={keyname} className={tdclass}>
							<span className={labelclass}>{field.label+":"}</span>
							{formatTools.utf8str(fieldvalue)}
						</td>
				}
			});
		}
		return <></>
	}

	function generatefilterlist(filterlist, basekey)
	{
		return filterlist.map((filteritem, idx)=>{
			return <button key={basekey+idx} className={"textbutton"} onClick={(e)=>{removefilter(idx)}}>
					<span>{filteritem.display}</span>
					<img src={imgclose} alt="x" />
				</button>
		})
	}

	function removefilter(removeindex)
	{
		var currentfilter = [...filters];
		currentfilter.splice(removeindex,1);
		setFilters(currentfilter);
	}

	function handleNewFilter(displaytext, newfield, newoperation, newvalue)
	{
		var currentfilter = [...filters];
		currentfilter.push({
			display: displaytext,
			field: newfield,
			operation: newoperation,
			value: newvalue
		});
		setFilters(currentfilter);
	}

	function generateFilterField(field, idx)
	{
		return <FilterField
					key={""+idx}
					basekey={""+idx}
					type={field.filtertype}
					label={field.label}
					field={field.dbfield}
					filters={filters}
					handleRemove={removefilter}
					handleSubmit={handleNewFilter}
				/>
	}

	function generateSortControl()
	{
		var sortfields = [];
		var tmpidx = 0;
		while (tmpidx < fieldList.length) {
			if (!UNSORTABLELIST.includes(fieldList[tmpidx].type)) {
				sortfields.push({
					key:"sortmobileoption"+tmpidx,
					code:fieldList[tmpidx].dbfield,
					name:fieldList[tmpidx].label
				});
			}
			tmpidx++;
		}
		return <>
			<ControlSelect
				list={sortfields}
				defaultvalue={orderbyfield}
				handleChange={(newfield)=>{setOrderbyfield(newfield);setOrderbymode("");}}
				disabled={disabled || loading}
				/>
			&nbsp;&nbsp;
			Order: <ControlSelect
				list={[
					{"key":"sortordermobileoption0", code:"", name:"A to Z"},
					{"key":"sortordermobileoption1", code:"desc", name:"Z to A"},
				]}
				defaultvalue={orderbymode}
				handleChange={setOrderbymode}
				disabled={disabled || loading}
				/>
		</>
	}

	return <>
		<div className={styles.searchheadercontrol+" text-centered"}>
			<h2 className={pageaction==="Pick"?styles.picktitle:styles.pagetitle}>
				{pageaction+" "+pagetitle}
				{pickedcount > 0 && <>{" ("+pickedcount} selected)</>}
				{pageaction==="Pick" && <>
					<button title={"Cancel"} onClick={(e)=>{defaultPickhandler(e, false);}} className={"iconbutton iconbutton-medium "+styles.searchtablepickcancel}>
						<img src={imgclose} alt="Cancel" />
					</button>
					<span className={styles.searchtablepickdone}>
						<button title={"OK"} onClick={(e)=>{defaultPickhandler(e, true);}} className={"iconbutton iconbutton-medium"} disabled={pickedcount< 1}>
							<img src={imgcheck} alt="OK" />
						</button>
					</span>

				</>}
			</h2>
			<div>
				<button
					className={"iconbutton iconbutton-medium"}
					disabled={disabled || loading}
					onClick={(e)=>{setShowfilter(true)}}
					title={"Filter"}
				>
					<img src={imgfilter} alt="Filter" />
				</button>
				&nbsp;
				&nbsp;
				<button
					className={"iconbutton iconbutton-medium"}
					disabled={disabled || loading}
					onClick={(e)=>{refreshData()}}
					title={"Refresh"}
				>
					<img src={imgrefresh} alt="Refresh" />
				</button>
				{allowadd &&<>
					&nbsp;
					&nbsp;
					<button
						className={"iconbutton iconbutton-medium"}
						disabled={disabled || loading}
						onClick={defaultNewDocumentClick}
						title={"New"}
					>
						<img src={imgadddoc} alt="New Document" />
					</button>
				</>
				}
			</div>
			<div className={styles.seachtablemobilesortcontrol+" fullblock-mobile-only text-centered"}>
				Sort: {generateSortControl()}
			</div>
			{filters.length > 0 && <div className={"text-centered"}>
				Filters:
				<button
					className={"iconbutton iconbutton-medium"}
					disabled={disabled || loading}
					onClick={clearFilters}
					title={"Clear"}
				>
					<img src={imgfilterclear} alt="Clear" />
				</button>
				{generatefilterlist(filters, "searchtablefilteritem")}
			</div>}
		</div>
		<div className={pageaction==="Pick"?tablescroll.headerfooter + " "+styles.picktableholder:tablescroll.headerfooter + " "+styles.searchtableholder}>
			<table className={styles.searchtable}>
				{loading?
					<tbody>
						<tr>
							<td colSpan={FULLCOLSPANVALUE} style={{borderBottom:"none"}}>
								<div className="text-centered font-size-medium">
									Loading...
								</div>
							</td>
						</tr>
					</tbody>
				:<>
					<thead>
						<tr className={styles.searchtableheader}>
							<td>#</td>
							{defaultSearchHeaderGenerator()}
						</tr>
					</thead>
					<tbody>{listdata.length < 1?
						<tr className={styles.searchtablerow} key={entity+"listnotfound"}>
							<td colSpan={FULLCOLSPANVALUE} className={"text-centered font-size-medium"}>No Records Found</td>
						</tr>
					:<>
						{listdata.map((entry, idx)=> {
							return <tr className={styles.searchtablerow} key={entity+"list"+idx} onClick={(e)=>{e.preventDefault(); defaultSearchRowClick(e,entry)}}>
								<td className={styles.searchtablecellright+" "+styles.searchtablerownum}>
									<span className="fullblock-desktop-only">
										{idx+pageidx*paginationitems+1}
									</span>
									<span className="fullblock-mobile-only">
										{getMobileTitleRow(entry, idx)}
									</span>
								</td>
								{defaultSearchRowGenerator(entry, idx)}
							</tr>
						})}
					</>}</tbody>
					<tfoot>
						<tr>
							<td colSpan={FULLCOLSPANVALUE} className={styles.searchtablefooter}>
								<div className={styles.searchtablefooter}>
									<div className={styles.searchtablesubcontrol} >
										<ControlSelect
												list={[
													{key:"searchtable"+20, code:"20", name:"20 / Page"},
													{key:"searchtable"+50, code:"50", name:"50 / Page"},
													{key:"searchtable"+100, code:"100", name:"100 / Page"}
												]}
												defaultvalue={""+paginationitems}
												handleChange={updatePaginationitems}
												disabled={disabled || loading}
											/>
										&nbsp;
										&nbsp;
									</div>
									<button
										title={"Previous"}
										className={"iconbutton iconbutton-medium"}
										disabled={disabled || pageidx===0 || loading}
										onClick={(e)=>{setPageidx(pageidx-1)}}
									>
										<img src={listdata.length>0? imgnavprev : imgboxholder} alt="<" />
									</button>
									{listdata.length > 0?<div className={styles.searchtablepageinfo}>
										Page
										<button
											title={"Go to Page #"}
											className={"textbutton"}
											disabled={disabled || loading}
											onClick={(e)=>{setEditpageidx(true)}}
										>
											{pageidx+1}
										</button>
										{editpageidx &&
											<ControlFloatTextbox
												disabled={loading}
												displaytext={"Set Page #"}
												defaultvalue={(pageidx+1)+""}
												handleCancel={()=>{setEditpageidx(false)}}
												handleChange={updatePageidx}
											/>
										}
										of {Math.ceil(totalrecord/paginationitems)}
									</div>:<>
										{loading?
											<>Loading...</>
										:
											<>No records found</>
										}
									</>}
									<button
										title={"Next"}
										className={"iconbutton iconbutton-medium"}
										disabled={disabled || hasnextpage===false || loading}
										onClick={(e)=>{setPageidx(pageidx+1)}}
									>
										<img src={listdata.length>0? imgnavnext : imgboxholder} alt=">" />
									</button>
								</div>
							</td>
						</tr>
					</tfoot>
				</>}
			</table>
		</div>
		<div className="font-size-medium">&nbsp;</div>
		{showfilter && <div role="button" tabIndex='0'  className={styles.searchtablefiltershadow} onClick={(e)=>{setShowfilter(false);}} onKeyDown={(e)=>{setShowfilter(false);}}>&nbsp;</div>}
		<div className={styles.searchtablefilter+" "+(showfilter?styles.searchtablefilterenter:styles.searchtablefilterleave)+" text-centered"}>
			<div className={styles.searchtablefiltercancel}>
				<div className={styles.searchtablefilterbodytitle+" font-size-medium"}>Filters</div>
				<div className={styles.searchtablefilterbodylist+" text-centered"}>
					<div className={"font-style-italic"}>
						{formatTools.integerstr(totalrecord)} results(s)
						{filters.length > 0 && <>
							&nbsp;found
							<button
								className={"iconbutton iconbutton-medium"}
								disabled={disabled || loading}
								onClick={clearFilters}
								title={"Clear"}
							>
								<img src={imgfilterclear} alt="Clear" />
							</button>
						</>}
					</div>
				</div>
				<button
					title={"Close"}
					className={styles.searchtablefilterbodybutton+" iconbutton iconbutton-medium"}
					disabled={disabled || loading}
					onClick={(e)=>{setShowfilter(false)}}
				>
					<img src={imgclose} alt="Close" />
				</button>
				<hr/>
			</div>
			<div className={styles.searchtablefilterbody}>
				{ fieldList.length > 0 &&
					fieldList.map((field, idx)=> {
						return generateFilterField(field, idx);
					})
				}
			</div>
		</div>
	</>
}


WebappSearch.propTypes = {
	initsearchstate: PropTypes.object,
	pageaction: PropTypes.string,
	pagetitle: PropTypes.string,
	mobilerowtitlefield: PropTypes.array,
	pickedcount: PropTypes.number,
	allowadd: PropTypes.bool,
	fieldList: PropTypes.array,
	userfilter: PropTypes.array
};

WebappSearch.defaultProps = {
	pageaction: "Search",
	pagetitle: "",
	mobilerowtitlefield: [],
	pickedcount: 0,
	initsearchstate: {},
	disabled: false,
	allowadd: true,
	fieldList: [],
	userfilter: []
};


export default WebappSearch;
