
import React from "react"

import ApijwtuserForm from "../../components/webapps/forms/apijwtuser";

const UserloginPage = ({location}) => {

	return <ApijwtuserForm
				location={location}
				allowadd={true}
				allowdelete={false}
				customeditformfields={[]}
				customnewformfields={[]}
			/>


}


export default UserloginPage;
