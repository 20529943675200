
import React, {useState} from "react"
import {Buffer} from 'buffer'
import PropTypes from "prop-types"

import imgdownload from "../../../images/buttons/download.png"
import imgdelete from "../../../images/buttons/delete.png"

import imgcheck from "../../../images/buttons/done.png"
import imgclose from "../../../images/buttons/close.png"

const webappsAPI = require("../../../../lib/requestWebapps");


const WebappDownloader = ({pkid, fieldinfo, disabled, allowdelete, handleFileDelete, token}) => {
	const [message, setMessage] = useState("");
	const [downloading, setDownloading] = useState(false);
	const [deletemode, setDeletemode] = useState(false);

/*
		const dlentity = webappsAPI.getTablefromDBfield(dlfield).toLowerCase();
		webappsAPI.downloadFile(dlentity, dlfield, dlpkid, token).then(response => {

			var b64data = "";
			const url = window.URL.createObjectURL(
				atob(b64data)
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`FileName.pdf`,
			);

			// Append to html link element page
			document.body.appendChild(link);

			// Start download
			link.click();

			// Clean up and remove the link
			link.parentNode.removeChild(link);
		});
*/

	const downloadData = (fname, dldata, isbase64 = true) => {
		const blob = new Blob([Buffer.from(dldata, isbase64?"base64":"utf8")], {type: "octet/stream"});
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute(
			'download',
			fname,
		);

		// Append to html link element page
		document.body.appendChild(link);

		// Start download
		link.click();

		// Clean up and remove the link
		link.parentNode.removeChild(link);
		setDownloading(false);
	}

	const startdownload = (e) => {
		if (e) {
			e.preventDefault();
		}
		setDownloading(true);
		const fileprefix = fieldinfo.value.substring(0,1);
		if (fileprefix !== "s" && fileprefix !== "h") {
			const fileinfo = webappsAPI.getFilePayloadInfo(fieldinfo.value);
			if (fileinfo.dataidx > 0) {
				downloadData(fileinfo.fname,btoa(fieldinfo.value.substring(fileinfo.dataidx)));
			} else {
				setDownloading(false);
			}
			return;
		}
		webappsAPI.downloadFile(webappsAPI.getTablefromDBfield(fieldinfo.field), fieldinfo.field, pkid, token).then(response => {
			if (response.status==="OK") {
				if (response.fname.length > 0) {
					downloadData(response.fname, response.base64);
					return;
				}
			}
			setDownloading(false);
		});
	}

	const verifyDeleteAction = (e) => {
		if (e) {
			e.preventDefault();
		}
		if (typeof handleFileDelete === "undefined") {
			return;
		}
		setMessage("Please confirm removal");
		setDeletemode(true);
	}

	const cancelAction = (e) => {
		if (e) {
			e.preventDefault();
		}
		setMessage("");
		setDeletemode(false);
	}

	const confirmAction = (e) => {
		if (e) {
			e.preventDefault();
		}
		handleFileDelete();
		setMessage("");
		setDeletemode(false);
	}


	if (fieldinfo.value.length < 1) {
		return <></>
	}
	return( <>
		{message !== "" && <div>{message}</div>}
		{deletemode ? <>
			<button title={"Cancel"} disabled={disabled || downloading} className={"iconbutton iconbutton-large"} onClick={cancelAction} >
				<img src={imgclose} alt="Cancel"/>
			</button>
			<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
			<button title={"OK"} disabled={disabled || downloading} className={"iconbutton iconbutton-large"} onClick={confirmAction} >
				<img src={imgcheck} alt={"OK"} />
			</button>
		</>:<>
			<button
				disabled={disabled || downloading}
				className={"textbutton"}
				onClick={startdownload}
				title={"Download"}
			>
				<img src={imgdownload} alt={"Download"} />
			</button>
			{allowdelete === true &&
				<button
					disabled={disabled || downloading}
					className={"textbutton"}
					onClick={verifyDeleteAction}
					title={"Delete"}
				>
					<img src={imgdelete} alt={"Delete"} />
				</button>
			}
		</>}
	</>

	)
}


WebappDownloader.propTypes = {
	allowdelete: PropTypes.bool,
	disabled: PropTypes.bool
}


WebappDownloader.defaultProps = {
	allowdelete: true,
	disabled: false
}

export default WebappDownloader
