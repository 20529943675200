import React from 'react';
import PropTypes from "prop-types"

import ControlSelect from "../../controls/select";
import ControlRadio from "../../controls/radio";
import ControlCheckbox from "../../controls/checkbox";
import ImageUpload from "../../controls/imageupload";
import FileUpload from "../../controls/fileupload";
import ControlRichTextarea from '../../controls/richtextarea';
import WebappDownloader from "./webappdownloader";
import WebappDLImage from './webappdlimage';

import imgpick from "../../../images/controls/select-arrow.png"
import imgnewwindow from "../../../images/buttons/openinnew.png"

import * as styles from "../style.module.css"

const MAX_IMAGEDIMENSION=2048;

const webappsAPI = require("../../../../lib/requestWebapps");
const formatTools = require("../../../../lib/formatTools");

/*
	// Field Attributes
	{
		"label": "",	// Label
		"field": "",	// dbfield
		"value": "", 	// Value
		"input": "",	// [textbox, textarea, currency, integer, numeric, checkbox, picker, combo, file, image, updatetime, createtime, date, datetime, url, hidden]
		"mode": "",		// [normal, readonly, required, unique, editonce]
		"subtotalid": "",	// OPTIONAL: Sub form column total variable
		"destfield": "",	// OPTIONAL: Destination dbfield
		"info": "",		// Info button help text

		////////
		// Special attributes
		////////
		// input=url
		"urlprefix": "", // OPTIONAL: Default prefix for URL value, eg. https://website.com/

		// input=file or input=image
		"uploadtype": "", // OPTIONAL: ['https', 'webapp', ''] Storage format: https=raw, webapp=webapp, blank=webapp in db record
		"filehost": "", // OPTIONAL: webhost; no slashes and http/https prefix
		"filelocation": "", // OPTIONAL: folder for file; no leading and tailing slashes

		// input=image
		"imagemode": "",	// OPTIONAL: [as-is, limit, fit, fill, stretch] Resize/cropping rule
		"imagewidth": "",	// OPTIONAL: Integer, Will be required for some imagemode
		"imageheight": "",	// OPTIONAL: Integer, Will be required for some imagemode
		"imageformat": "", 	// OPTIONAL: [png, jpg] Not supported

	}
*/

const FormField = ({pkid, token, fieldid, fielddetails, initializing, disabled, viewmode, displaymode, controlkey, handleChange, showPicker, removePicker}) => {

	const defaultHandleChange = (newvalue) => {
		if (typeof handleChange === "undefined") {
			return;
		}
		const type = fielddetails.input;
		var finalvalue = newvalue;
		//console.log(type,index, newvalue);
		if (type === "integer" || type === "checkbox") {
			finalvalue = formatTools.filterInteger(newvalue);
		} else if (type === "numeric" || type === "currency") {
			finalvalue = formatTools.filterNumeric(newvalue);
		} else if (type === "datetime") {
			finalvalue = formatTools.datetimestr(newvalue, "ymdhis");
		}
		handleChange(finalvalue, type, fieldid);
	};

	const handleFileInputChange = (index, outfname, base64data, mimetype)=> {
		//console.log(mimetype,outfname, base64data.length);
		if (typeof handleChange === "undefined") {
			return;
		}
		handleChange(outfname.length+"|"+outfname+base64data, fielddetails.input, fieldid);
	};

	const handleFileDelete = () => {
		if (typeof handleChange === "undefined") {
			return;
		}
		handleChange(0+"|", fielddetails.input, fieldid);
	};

	const showFileActions = (entry, displayonly) => {
		if (entry.modified) return <></>
		return <div className={"text-centered "+styles.editfieldfilecontrol}>
				<WebappDownloader
					allowdelete={displayonly===false}
					token={token}
					fieldinfo={entry}
					disabled={disabled}
					pkid={pkid}
					handleFileDelete={handleFileDelete}
				/>
		</div>
	};

	const showTextAreaValue = (value) => {
		return value.split("\n").map((content)=>{
			if (content.length < 1) {
				return <p>&nbsp;</p>
			}
			return <p>{content}</p>
		});
	};

	const showInput = (entry) => {
		const subformmode = displaymode==="subform";
		const defaultstyle=(subformmode?(fielddetails.modified===true&&viewmode?styles.editsubformcellmodified:""):styles.editfield)
		const displayonly = (viewmode === true || (entry.mode === "editonce" && entry.value !== ""  && entry.modified === false) || entry.mode === "readonly" || webappsAPI.isDBField(entry.field) === false);
		if (entry.input === "checkbox") {
			return <ControlCheckbox
						disabled={entry.mode === "readonly" || disabled || displayonly === true}
						defaultvalue={entry.value+""}
						key={"controlcheckbox"+fieldid+controlkey}
						handleChange={defaultHandleChange}
					/>
		} else if (entry.input === "radiocheck") {
			if (displayonly === true) {
				return <div className={defaultstyle}> {""+entry.value === "0"? "No":"Yes"} </div>
			}
			return <ControlRadio
				disabled={entry.mode === "readonly" || disabled}
				defaultvalue={""+entry.value}
				key={"controlradio"+fieldid+controlkey}
				list={[
					{"name": "Yes", "code": "1"},
					{"name": "No", "code": "0"}
				]}
				handleChange={defaultHandleChange}
			/>
		} else if (entry.input === "textarea") {
			if (displayonly === true) {
				return <div className={defaultstyle}> {showTextAreaValue(entry.value)} </div>
			}
			return <textarea
				disabled={entry.mode === "readonly" || disabled}
				className={"font-size-small "+defaultstyle + " "+styles["editfield"+entry.input]}
				rows={5}
				onChange={(e) => {defaultHandleChange(e.target.value);}}
				defaultValue={entry.value} />
		} else if (entry.input === "richtextarea") {
				return <ControlRichTextarea
						disabled={entry.mode === "readonly" || disabled || displayonly}
						//onChange={(e) => {defaultHandleChange(e.target.value);}}
						defaultValue={entry.value}
						handleChange={defaultHandleChange}
					/>
		} else if (entry.input === "file") {
			var fname = "No file";
			if (entry.value !== "") {
				const fileuploadprefix = entry.value.substring(0,1);
				const fileuploadinfo = webappsAPI.getFilePayloadInfo(entry.value);
				if (fileuploadinfo.dataidx > 2) {
					fname = fileuploadinfo.fname;
				} else if (fileuploadprefix === "h") {
					const fnameidx = entry.value.lastIndexOf("/");
					if (fnameidx >= 0) {
						fname = entry.value.substring(fnameidx+1);
					}
				} else if (fileuploadprefix === "s") {
					fname = "Available for download";
				} else {
					fname = "No file";
				}
			}
			if (displayonly === true) {
				return <>
					{fname}
					{showFileActions(entry, displayonly)}
				</>
			}

			return <div className={defaultstyle}>
					<FileUpload
						defaultfname={fname}
						disabled={entry.mode === "readonly" || disabled}
						uploadHandler={handleFileInputChange}
						className={defaultstyle + " "+styles["editfield"+entry.input]}
						//curdata={entry.value}
					>
						{showFileActions(entry, displayonly)}
					</FileUpload>
				</div>
		} else if (entry.input === "image") {
			var imgmode = "limit";
			var imgwidth = MAX_IMAGEDIMENSION;
			var imgheight = MAX_IMAGEDIMENSION;
			var imgformat = "";

			if (entry.hasOwnProperty("imagemode")) {
				imgmode = entry.imagemode;
				if (entry.hasOwnProperty("imagewidth") && entry.hasOwnProperty("imageheight")) {
					imgwidth = entry.imagewidth;
					imgheight = entry.imageheight;
				}
			}
			if (entry.hasOwnProperty("imageformat")) {
				imgformat = entry.imageformat;
			}

			var imgsrc = "";
			var webappdlimg = "";
			if (entry.value !== "") {
				const fileinfo = webappsAPI.getFilePayloadInfo(entry.value);
				if (fileinfo.dataidx > 2 && fileinfo.mimetype.substring(0, 6) === "image/") {
					imgsrc = "data:"+fileinfo.mimetype+";base64,"+entry.value.substring(fileinfo.dataidx);
				} else if (entry.value.substring(0,1) === "h") {
					imgsrc = entry.value;
				} else {
					webappdlimg = entry.value;
				}
			}
			return <div className={defaultstyle}>
					<ImageUpload
						disabled={entry.mode === "readonly" || displayonly === true || disabled}
						outputFormat={imgformat}
						sizeMode={imgmode}
						targetWidth={imgwidth}
						targetHeight={imgheight}
						uploadHandler={handleFileInputChange}
						className={defaultstyle + " "+styles["editfield"+entry.input]}
						srcurl={imgsrc}
						shownoimage={webappdlimg===""}
						>
						{webappdlimg !== "" &&
						<WebappDLImage
							token={token}
							srcurl={webappdlimg} />}
						{showFileActions(entry, displayonly)}
					</ImageUpload>
				</div>
		} else if (entry.input === "picker") {
			var pickerhasvalue = false;
			var tmppickdisplay = "";
			if (entry.value !== null) {
				tmppickdisplay = formatTools.utf8str(entry.value);
			}
			if (displayonly === true || typeof showPicker === "undefined" || typeof removePicker === "undefined") {
				return <> {tmppickdisplay} </>
			}
			if (tmppickdisplay.length < 1) {
				if (entry.mode === "required" || entry.mode === "unique") {
					tmppickdisplay = "Please choose";
				} else {
					tmppickdisplay = "-";
				}
			} else {
				pickerhasvalue = true;
			}

			return <>
					<button style={{marginRight:"0px"}} title={"Pick"} disabled={entry.mode === "readonly" || disabled} className={"textbutton"} onClick={(e)=>{showPicker(entry, fieldid)}}>
						{tmppickdisplay} <img src={imgpick} alt={"Pick"} />
					</button>
					{pickerhasvalue && <button style={{marginLeft:"2px"}} title={"Remove"} disabled={entry.mode === "readonly" || disabled} className={"textbutton"} onClick={(e)=>{removePicker(entry, fieldid)}}>
						X
					</button>}
				</>
		} else if (entry.input === "combo") {
			var tmpcombodisplay = "";
			var tmpcomboidx = 0;
			var tmpcombooptions = [];
			var tmpcombovalue = "";
			var noneSelectedText = "";

			if (entry.value !== null && entry.value !== 0 && entry.value !== "") {
				tmpcombovalue = ""+entry.value;
			}

			while (tmpcomboidx < entry.options.length) {
				if (tmpcombovalue !== "") {
					if (entry.options[tmpcomboidx].value+""  === tmpcombovalue) {
						tmpcombodisplay = entry.options[tmpcomboidx].display;
					}
				}
				tmpcombooptions.push({
					key:"controlselect"+fieldid+controlkey+tmpcomboidx,
					code:""+entry.options[tmpcomboidx].value,
					name:entry.options[tmpcomboidx].display
				});
				tmpcomboidx++;
			}

			if (displayonly === true) {
				return <> {tmpcombodisplay} </>
			}
			if (entry.mode === "required" || entry.mode === "unique") {
				noneSelectedText = "Please choose";
			}

			return <>
					<ControlSelect
							basekey={"controlselect"+fieldid+controlkey}
							textNone={noneSelectedText}
							list={tmpcombooptions}
							defaultvalue={tmpcombovalue}
							handleChange={defaultHandleChange}
							disabled={entry.mode === "readonly" || disabled}
						/>
					</>
		}
		var addstyle="";
		var inputtype=null;
		var inputvalue=entry.value;
		if (entry.mode === "readonly" || displayonly === true || entry.input === "createtime" || entry.input === "updatetime") {
			var tmpval = entry.value;
			var tmpclassname = "";
			if (entry.input === "currency") {
				tmpclassname=(subformmode?" "+styles.editsubformcellnumeric:"");
				tmpval = formatTools.currencystr(entry.value);
			} else if (entry.input === "integer") {
				tmpclassname=(subformmode?" "+styles.editsubformcellnumeric:"");
				tmpval = formatTools.integerstr(entry.value);
			} else if (entry.input === "numeric") {
				tmpclassname=(subformmode?" "+styles.editsubformcellnumeric:"");
				tmpval = formatTools.numericstr(entry.value, 5);
			} else if (entry.input === "date") {
				tmpval = formatTools.datestr(entry.value);
			} else if (entry.input === "datetime" || entry.input === "createtime" || entry.input === "updatetime") {
				tmpval = formatTools.datetimestr(entry.value);
			} else if (entry.input === "url") {
				tmpval = "-";
				if (entry.value) {
					if (entry.value.length > 0) {
						const urlprefix=formatTools.defaultstr(entry, "urlprefix", "");
						return <div className={defaultstyle+" noprint"}>
							<a className={"font-size-small "+styles.editfieldlink} style={{textDecoration:"underline"}} href={urlprefix+entry.value} rel="noreferrer" target={"_blank"}>
								Click Here to View
								<img src={imgnewwindow} alt={"Open"} />
							</a>
						</div>
					}
				}
			}
			return <div className={defaultstyle+tmpclassname}>{tmpval}</div>
		} else {
			if (entry.input === "date") {
				addstyle = styles.editfielddate;
				inputtype = "date";
				inputvalue = entry.value.substring(0,10);
			} else if (entry.input === "datetime") {
				addstyle = styles.editfielddatetime;
				inputtype = "datetime-local";
			}
		}
		return <input
				type={inputtype}
				disabled={disabled}
				className={"font-size-small "+defaultstyle + " " + addstyle + " "+styles["editfield"+entry.input]}
				value={inputvalue}
				onChange={(e) => {defaultHandleChange(e.target.value);}}
			/>
	};

	const infotext = fielddetails.hasOwnProperty("info") ? fielddetails.info : "";

	return <>
		{fielddetails.input !== "hidden" &&
			<>
				<div key={"field_"+fieldid} className={styles.editfieldrow}>
					{displaymode!=="subform" && <label className={"font-size-regular "+styles.editfieldlabel+((fielddetails.modified===true&&viewmode)?" "+styles.editfieldlabelmodified:"")}>
						{fielddetails.label}
						{(fielddetails.mode === "unique"||fielddetails.mode === "required") && <>*</>}
					</label>}
					{initializing===false && showInput(fielddetails)}
				</div>
				{(infotext !== "") && <>
					<div className={'font-size-tiny '+styles.editfieldrow+" "+styles.editfieldrowinfo}>({infotext})</div>
				</>}

			</>
		}
	</>

}


FormField.propTypes = {
	initializing: PropTypes.bool,
	disabled: PropTypes.bool,
	viewmode: PropTypes.bool,
	displaymode: PropTypes.string
}


FormField.defaultProps = {
	initializing: false,
	disabled: false,
	viewmode: false,
	displaymode: "form"
}

export default FormField
