
import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"

import WebappSearch from "./search"
import WebappEdit from "./edit"

import imgcheck from "../../images/buttons/done.png"

const webappsAPI = require("../../../lib/requestWebapps");

/*
//TEMPLATE
const searchFields = [
	{"label":"", "dbfield": "", "type": "", "filtertype": ""}
];

const formFields = [
	[
		{"label":"", "field": "", "value": "", "input": "", "mode": ""}
	]
];
*/

const WebappsForm = ({pagetitle, mobilerowtitlefield, token, entity, searchFields, editFields, newformFields, editSubFormFields, newSubformFields, allowadd, allowdelete, customReload, customSubmit, customDeleteDone, customSearchField, customEditFieldInfo, customFieldFragment, customValidateValues, customPicker, customSetDocumentId, customSetFormData, userSearchFilter, userSearchParam, userEditJoincond}) => {
	const [message, setMessage] = useState("Loading...");
	const [confirmmsg, setConfirmmsg] = useState("");
	const [initializing, setInitializing] = useState(false);
	const [loading, setLoading] = useState(false);
	const [editid, setEditid] = useState(-1);
	const [fieldList, setFieldList] = useState(searchFields);
	const [formFields, setFormFields] = useState(editFields);
	const [subformFields, setSubformFields] = useState(editSubFormFields);
	const [formData, setFormData] = useState({});
	const [dbfieldlist, setDbfieldlist] = useState([]);
	const [dbsubfieldlist, setDbsubfieldlist] = useState([]);

	const [searchstate, setSearchState] = useState({})

	useEffect(()=> {
		function getDBFields(dbfieldlist, formfieldlist)
		{
			if (!formfieldlist) {
				return dbfieldlist;
			}
			var tmpdbfield = "";
			var idx = 0;
			while (idx < formfieldlist.length) {
				tmpdbfield=formfieldlist[idx].field;
				if (tmpdbfield.charAt(0)==="#") {
					dbfieldlist.push(tmpdbfield);
				} else if (webappsAPI.isDBField(tmpdbfield)) {
					if (!dbfieldlist.includes(tmpdbfield)) {
						dbfieldlist.push(tmpdbfield);
					}
				}
				idx++;
			}
			return dbfieldlist;
		}
		function loadDBFieldlist(curformfields, subforminfo)
		{
			var tmpdbfieldlist = [];
			var idx = 0;

			while (idx < curformfields.length) {
				tmpdbfieldlist = getDBFields(tmpdbfieldlist, curformfields[idx]);
				idx++;
			}

			// Get footer dbfields
			idx = 0;
			while (idx < subforminfo.length) {
				tmpdbfieldlist = getDBFields(tmpdbfieldlist, subforminfo[idx].footerlist);
				idx++;
			}
			setDbfieldlist(tmpdbfieldlist);
		}
		function loadDBsubFieldlist(cursubformfields)
		{
			var tmpdbfieldlist = [];
			var subdbfieldlist = [];
			var subformidx = 0;
			while (subformidx < cursubformfields.length) {
				subdbfieldlist = getDBFields([], cursubformfields[subformidx].fieldlist);
				if (subdbfieldlist.length > 0) {
					tmpdbfieldlist.push(subdbfieldlist);
				}
				subformidx++;
			}
			setDbsubfieldlist(tmpdbfieldlist);
		}
		function loadEditFormFields()
		{
			if (editFields.length < 1) {
				webappsAPI.loadForm(entity, token).then(formresponse => {
					if (formresponse.hasOwnProperty("fields")) {
						webappsAPI.subformFields(entity, token).then(subformresponse => {
							loadDBFieldlist(formresponse.fields, subformresponse.data);
							loadDBsubFieldlist(subformresponse.data);

							setFormFields(formresponse.fields);
							setSubformFields(subformresponse.data);
						});
					} else {
						setFormFields([]);
						setConfirmmsg("Unable to load form data. Please reload to try again.")
					}
					setMessage("");
					setLoading(false);
				});
			} else {
				// formFields and subformFields already set during initialization
				loadDBFieldlist(editFields, editSubFormFields);
				loadDBsubFieldlist(editSubFormFields);
				setMessage("");
				setLoading(false);
			}
		}

		let isMounted = true; // Prevent leaks
		if (isMounted && token.length > 0) {
			setLoading(true);
			if (searchFields.length < 1) {
				webappsAPI.searchFields(entity, token).then(response => {
					if (response.hasOwnProperty("data")) {
						setFieldList(response.data);
						loadEditFormFields();
					} else {
						setFieldList([]);
						setFormFields([]);
						setConfirmmsg("Unable to load data. Please reload to try again.")
						setLoading(false);
					}
				});
			} else {
				loadEditFormFields();
			}

		}
		return () => { isMounted = false };
	}, [entity, token, editFields, searchFields, editSubFormFields])

	function newDocumentClick(newsearchstate)
	{
		setSearchState(newsearchstate);
		var newdata = {"subform": []};
		var idx = 0;
		while (idx < subformFields.length) {
			newdata.subform.push([]);
			idx++;
		}
		handleSetFormData(newdata);
		handleSetDocid(0);
	}

	function searchRowClick(e, rowdata, newsearchstate)
	{
		setSearchState(newsearchstate);
		loadFormData(rowdata[entity.toLowerCase()+"_id"]);
	}

	function loadFormData(pkid)
	{
		setInitializing(true);
		handleSetDocid(0);
		setLoading(true);
		webappsAPI.loadData(entity, {"documentid":pkid, "dbfieldlist": dbfieldlist, "joincond": userEditJoincond}, token).then(response => {
			if (response.status === "OK") {
				if (response.count > 0) {
					loadSubFormData(pkid,0, [], function(subformdata) {
						loadFormHistory(pkid, function(historylist) {
							var tmpformdata = response.data[0];
							tmpformdata.subform = subformdata;
							tmpformdata.history = historylist;
							handleSetFormData(tmpformdata);
							handleSetDocid(pkid);
							setLoading(false);
							setInitializing(false);
						});
					});

					return;
				}
			}
			handleSetDocid(-1);
			setConfirmmsg("Unable to load data. Please try again.");
			setLoading(false);
			setInitializing(false);
		});
	}

	function loadSubFormData(pkid, idx, curoutput, callback)
	{
		if (idx < subformFields.length) {
			webappsAPI.loadData(subformFields[idx].table, {
					"getallrows": true,
					//"count":10000,	// Some high number so all rows will be returned
					"dbfieldlist": dbsubfieldlist[idx],
					"filters": [[
						{"field":entity.toLowerCase()+"_id", "operation":"=", "value": pkid}
					]],
					"orderby": subformFields[idx].sort
				}, token).then(response => {
					if (response.status === "OK") {
						if (response.count > 0) {
							curoutput.push(response.data);
							loadSubFormData(pkid,idx+1, curoutput, callback);
							return;
						}
					}
					curoutput.push([]);
					loadSubFormData(pkid, idx+1, curoutput, callback);
				});
		} else {
			callback(curoutput);
		}
	}

	function loadFormHistory(pkid, callback)
	{
		webappsAPI.loadData("dbactivity", {
			"count":10000,	// Some high number so all rows will be returned
			"dbfieldlist": ["dbactivity_source", "dbactivity_date"],
			"filters": [[
				{"field":"dbactivity_table", "operation":" like ", "value": entity},
				{"field":"dbactivity_recordid", "operation":"=", "value": pkid}
			]],
			"orderby": "dbactivity_date desc"
		}, token).then(response => {
			if (response.status === "OK") {
				if (response.count > 0) {
					callback(response.data);
					return;
				}
			}
			callback([]);
		});
	}

	function editCancel(e)
	{
		handleSetDocid(-1);
	}

	function handleSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		if (typeof customSubmit !== "undefined") {
			customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback);
		} else {
			webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, callback);
		}
	}

	function handleDeleteDone(entity, pkid, payloadlist, token, subtablelist, callback)
	{
		if (typeof customDeleteDone !== "undefined") {
			customDeleteDone(entity, pkid, payloadlist, token, subtablelist, callback);
		} else {
			callback();
		}
	}

	function formValidateValues(mainform, subformlist, subformfooterlist)
	{
		// Already validated for basic checks at this point
		if (typeof customValidateValues !== "undefined") {
			return customValidateValues(mainform, subformlist, subformfooterlist);
		}
		return {"status":"OK"};
	}

	function formSearchField(field, idx, rowdata, styles)
	{
		if (typeof customSearchField !== "undefined") {
			return customSearchField(field, idx, rowdata, styles)
		}
		return <></>
	}

	function formEditFieldFragment(inputlist, idx)
	{
		if (typeof customFieldFragment !== "undefined") {
			return customFieldFragment(inputlist, idx)
		}
		return null;
	}
	function formEditFieldInfo(inputlist, idx)
	{

		if (typeof customEditFieldInfo !== "undefined") {
			return customEditFieldInfo(inputlist, idx)
		}
		return inputlist[idx];
	}

	function formPicker(pkid, inputList, inputSubList, inputFooterList, entry, fieldid, callback)
	{
		if (typeof customPicker !== "undefined") {
			customPicker(pkid, inputList, inputSubList, inputFooterList, entry, fieldid, callback);
		} else {
			callback(entry);
		}
	}

	function handleSetFormData(newformdata)
	{
		setFormData(newformdata);
		if (typeof customSetFormData !== "undefined") {
			customSetFormData(newformdata)
		}
	}

	function handleSetDocid(docid)
	{
		// 20240517: Disabled checking because it occasionally causes docid to become 0, concurrency maybe?
		//if (editid !== docid) {
			setEditid(docid)
			if (typeof customSetDocumentId !== "undefined") {
				customSetDocumentId(docid)
			}
		//}
	}

	function handleReload(docid)
	{
		if (typeof customReload !== "undefined") {
			customReload(docid);
		} else {
			loadFormData(docid);
		}
	}
	return <>
			{editid>=0?<>
				<WebappEdit
					initializing={initializing}
					handleReload={handleReload}
					customValidateValues={formValidateValues}
					customFieldInfo={formEditFieldInfo}
					customFieldFragment={formEditFieldFragment}
					customSubmit={handleSubmit}
					customDeleteDone={handleDeleteDone}
					customPicker={formPicker}
					disabled={loading}
					pagetitle={pagetitle}
					handleCancel={editCancel}
					documentid={editid}
					allowdelete={allowdelete}
					entity={entity}
					formFields={(editid > 0 || newformFields.length < 1) ? formFields : newformFields}
					subFormFields={(editid > 0 || newSubformFields.length < 1) ? subformFields: newSubformFields}
					formData={formData}
					token={token} />
			</>:<>
				{(message === "" && confirmmsg === "") ?
					<WebappSearch
						initsearchstate={searchstate}
						customShowField={formSearchField}
						disabled={loading}
						pagetitle={pagetitle}
						mobilerowtitlefield={mobilerowtitlefield}
						fieldList={fieldList}
						searchRowClick={searchRowClick}
						newDocumentClick={newDocumentClick}
						allowadd={allowadd}
						userfilter={userSearchFilter}
						userparam={userSearchParam}
						entity={entity}
						token={token} />
				:<>
					<br/><br/>
					<h2 className="text-centered">{confirmmsg === ""?message:confirmmsg}</h2>
					{confirmmsg!=="" &&<div className={"text-centered"}>
						<button title={"OK"} className={"iconbutton iconbutton-large"} onClick={(e)=>{setConfirmmsg("");}} >
							<img src={imgcheck} alt="OK"/>
						</button>
					</div>}
					<br/><br/>
				</>}
			</>}
		</>

}

WebappsForm.propTypes = {
	allowadd: PropTypes.bool,
	allowdelete: PropTypes.bool,
	pagetitle: PropTypes.string,
	mobilerowtitlefield: PropTypes.array,
	searchFields: PropTypes.array,
	editFields: PropTypes.array,
	newformFields: PropTypes.array,
	editSubFormFields:PropTypes.array,
	newSubformFields: PropTypes.array,
	userSearchFilter: PropTypes.array,
	userEditJoincond: PropTypes.string
}


WebappsForm.defaultProps = {
	allowadd: true,
	allowdelete: true,
	pagetitle: "",
	mobilerowtitlefield: [],
	searchFields: [],
	editFields: [],
	newformFields: [],
	editSubFormFields: [],
	newSubformFields: [],
	userSearchFilter: [],
	userSearchParam: {},
	userEditJoincond: ""
}

export default WebappsForm;
