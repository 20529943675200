
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import WebappSearch from "./search"

const webappsAPI = require("../../../lib/requestWebapps");

const WebappPicker = ({pagetitle, token, entity, userfilter, userparam, searchFields, multiselect, dataHandler, datadestination}) => {
	const [loading, setLoading] = useState(false);
	const [listdata, setListdata] = useState([]);
	const [fieldList, setFieldList] = useState(searchFields);

	useEffect(()=> {
		let isMounted = true; // Prevent leaks
		if (isMounted && token.length > 0 && searchFields.length < 1) {
			setLoading(true);
			webappsAPI.searchFields(entity, token).then(response => {
				if (response.hasOwnProperty("data")) {
					setFieldList(response.data);
				} else {
					setFieldList([]);
				}
				setLoading(false);
			});
		}
		return () => { isMounted = false };
	}, [entity, token, searchFields])

	function pickHandler(status)
	{
		if (status) {
			dataHandler(listdata, datadestination);
		} else {
			dataHandler([], datadestination);
		}
	}


	function defaultSearchRowClick(e, rowdata)
	{
		if (typeof dataHandler !== "undefined") {
			if (multiselect === false) {
				dataHandler([rowdata], datadestination);
			} else {
				var tmplistdata = [...listdata];
				tmplistdata.push(rowdata);
				setListdata(tmplistdata);
			}
		}
	}

	return <>
		<div>
			<WebappSearch
					pageaction="Pick"
					pickedcount={listdata.length}
					pagetitle={pagetitle}
					disabled={loading}
					token={token}
					entity={entity}
					userfilter={userfilter}
					userparam={userparam}
					fieldList={fieldList}
					allowadd={false}
					searchRowClick={defaultSearchRowClick}
					pickHandler={pickHandler}
				/>

		</div>
	</>
}


WebappPicker.propTypes = {
	datadestination: PropTypes.string,
	pagetitle: PropTypes.string,
	multiselect: PropTypes.bool,
	searchFields: PropTypes.array,
	userfilter: PropTypes.array
};

WebappPicker.defaultProps = {
	datadestination: "",
	pagetitle: "",
	multiselect: false,
	searchFields: [],
	userfilter: []
};


export default WebappPicker;
