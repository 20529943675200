
import React, {useEffect, useState, useCallback, useRef} from "react"
import PropTypes from "prop-types"

import WebappPicker from "./picker";
import FormField from "./controls/formfield";
import EditControlBox from "./controls/editcontrolbox";
import SubForm from "./controls/subform";

import * as tablescroll from "../../styles/module/tablescroll.module.css"
import * as styles from "./style.module.css"


import imgcheck from "../../images/buttons/done.png"
import imgclose from "../../images/buttons/close.png"

import imgexpandmore from "../../images/buttons/expand_more.png"
import imgexpandless from "../../images/buttons/expand_less.png"


const webappsAPI = require("../../../lib/requestWebapps");
const formatTools = require("../../../lib/formatTools");


const EMPTY_PICKER={"entity":"", "label":"", "datadestination":"", "fields":[],"filters":[]};

const DEFAULT_FILEHOST="files.webapps.iamnet.com.ph";
const DEFAULT_FILELOCATION="webappsiamnet";

/*
//TEMPLATE
const formFields = [
	[
		{"label":"", "field": "", "value": "", "input": "", "mode": ""}
	]
];

*/
const WebappEdit = ({initializing, pagetitle, disabled, allowdelete, token, entity, documentid, formFields, formData, subFormFields, handleReload, handleCancel, customFieldInfo, customFieldFragment, customValidateValues, customSubmit, customDelete, customDeleteDone, customPicker}) => {
	const headerElement = useRef();

	const [submitmode, setSubmitmode] = useState(false);
	// Added to handle save override
	const [submitaction, setSubmitaction] = useState("");
	const [submittitle, setSubmittitle] = useState("");
	const [submitsubtitle, setSubmitsubtitle] = useState("");

	const [displayhistory, setDisplayhistory] = useState(false);

	const [pkid, setPkid] = useState(documentid)
	const [loading, setLoading] = useState(false)
	const [inputdirty, setInputdirty] = useState(false);
	const [message, setMessage] = useState("Loading...");

	const [pickerinfo, setPickerinfo] = useState(EMPTY_PICKER);

	const [inputList, setInputList] = useState([]);
	const [inputSubList, setInputSubList] = useState([]);
	const [inputFooterList, setInputFooterList] = useState([]);
	const [referenceSubList, setReferenceSubList] = useState([]);
	const [formvariables, setFormVariables] = useState({});

	// Control key forces re-rendering
	const [controlboxkey, setControlboxkey] = useState("")
	const [controlkey, setControlkey] = useState("")

	const generateFormVariables = useCallback( (curformvariables, curinputdata, mainform) => {
		var subtotalid = "";
		var tmpdbfield = "";
		var curval = 0;
		var idx = 0;

		while (idx < curinputdata.length) {
			subtotalid = "";
			if (mainform === true) {
				tmpdbfield = curinputdata[idx].field;
				if (tmpdbfield.charAt(0)==="#") {
					tmpdbfield = tmpdbfield.substring(1);
				}
				if (webappsAPI.isDBField(tmpdbfield)) {
					subtotalid = tmpdbfield;
				}
			} else {
				if (curinputdata[0].value+"" === "0") {
					// Not marked for deletion
					subtotalid = formatTools.defaultstr(curinputdata[idx], "subtotalid", "");
				}
			}
			if (subtotalid !== "" && isNaN(parseFloat(curinputdata[idx].value)) === false) {
				if (subtotalid.slice(-3) !== "_id") {
					curval = parseFloat(curinputdata[idx].value);
					// Set/Replace if main form
					if (curformvariables.hasOwnProperty(subtotalid) && mainform === false) {
						curformvariables[subtotalid] = curformvariables[subtotalid] + curval;
					} else {
						curformvariables[subtotalid] = curval;
					}
				}
			}
			idx++;
		}

		return curformvariables;
	}, [])

	useEffect(()=> {
		let isMounted = true; // Prevent leaks
		if (isMounted) {
			function createInputList(maintable, tmpformfields, tmpformdata, subformmode)
			{
				var fieldidlist = [];
				var tmplist = [];
				var tmpfield = {};
				var rowidx = 0;
				var colidx = 0;
				var tmptable = "";
				var tmpdbfield = "";
				var isformula = false;

				if (subformmode) {
					tmpfield = {
						"label":"",
						"field": "markdelete",
						"value": 0,
						"input": "hidden",
						"mode": "",
						"modified": false
					};
					tmplist.push(tmpfield);

					tmpfield = {
						"label":"",
						"field": maintable.toLowerCase()+"_id",
						"value": formatTools.defaultstr(tmpformdata, maintable.toLowerCase()+"_id", "0")+"",
						"input": "hidden",
						"mode": "",
						"modified": false
					};

					tmplist.push(tmpfield);
				}
				// Get picker tables
				rowidx = 0;
				while (rowidx < tmpformfields.length) {
					colidx = 0;
					while (colidx < tmpformfields[rowidx].length) {
						tmpdbfield = tmpformfields[rowidx][colidx].field;
						if (tmpdbfield.charAt(0) !== "#") {
							if (webappsAPI.isDBField(tmpdbfield)) {
								tmptable = webappsAPI.getTablefromDBfield(tmpdbfield).toLowerCase();
								if ((tmpformfields[rowidx][colidx].input === "picker") && tmpformfields[rowidx][colidx].mode !== "readonly") {
									if (!fieldidlist.includes(tmptable+"_id")) {
										fieldidlist.push(tmptable+"_id");
									}
								}
							}
						}
						colidx++;
					}
					rowidx++;
				}

				// Load fields
				rowidx = 0;
				while (rowidx < tmpformfields.length) {
					colidx = 0;
					while (colidx < tmpformfields[rowidx].length) {
						tmpfield = JSON.parse(JSON.stringify(tmpformfields[rowidx][colidx]));
						tmpdbfield = tmpfield.field;
						isformula = false;
						if (tmpdbfield.charAt(0) === "#") {
							tmpdbfield = tmpdbfield.substring(1);
							tmptable = maintable.toLowerCase();
						} else {
							if (webappsAPI.isDBField(tmpdbfield) === false) {
								isformula=true;
							} else {
								tmptable = webappsAPI.getTablefromDBfield(tmpfield.field).toLowerCase();
							}
						}
						if (isformula === false) {
							if (tmpdbfield === tmptable+"_id"
							&& (tmpformfields[rowidx][colidx].input === "picker")) {
							   tmpdbfield = tmptable+"_name";
							}

							if (tmpformdata.hasOwnProperty(tmpdbfield)) {
								tmpfield.value = formatTools.defaultstr(tmpformdata, tmpdbfield, "")+"";
							} else if (tmpfield.value === null) {
								tmpfield.value = "";
							}
							if (tmpfield.value.length < 1) {
								if (tmpfield.input === "integer" || tmpfield.input === "numeric"
								|| tmpfield.input === "currency" || tmpfield.input === "checkbox") {
									tmpfield.value = 0;
								}
							}
						} else {
							tmpfield.value = webappsAPI.evalFormulaField(tmpdbfield, tmpformdata, {});
						}

						tmpfield.modified=false;
						tmplist.push(tmpfield);
						colidx++;
					}
					rowidx++;
				}
				// Hidden fields from combo/picker
				colidx = 0;
				while (colidx < fieldidlist.length) {
					tmpfield = {
						"label":"",
						"field": fieldidlist[colidx],
						"value": formatTools.defaultstr(tmpformdata, fieldidlist[colidx], "0")+"",
						"input": "hidden",
						"mode": "",
						"modified": false
					};
					tmplist.push(tmpfield);
					colidx++;
				}
				return tmplist;
			} // createInputList

			var tmpformvariables = {};
			var footerinputlist = [];
			var subforminputlist = [];
			var tmpreferencesubform = [];
			var tmprowdata = [];
			var subformidx = 0;

			Object.keys(formData).forEach(function(tmpfield) {
				if (tmpfield.indexOf("_")>0) {
					tmpformvariables[tmpfield]=formData[tmpfield];
				}
			});

			if (formData.hasOwnProperty("subform")) {
				var tableinputlist = [];
				var rowidx = 0;

				while (subformidx < subFormFields.length) {
					tableinputlist = [];
					rowidx = 0;
					while (rowidx < formData.subform[subformidx].length) {
						tmprowdata = createInputList(subFormFields[subformidx].table, [subFormFields[subformidx].fieldlist], formData.subform[subformidx][rowidx], true);
						tableinputlist.push(tmprowdata);
						tmpformvariables = generateFormVariables(tmpformvariables, tmprowdata, false);

						rowidx++;
					}
					tmpreferencesubform.push(createInputList(subFormFields[subformidx].table, [subFormFields[subformidx].fieldlist], {}, true));
					subforminputlist.push(tableinputlist);
					subformidx++;
				}
				// Footer Data, treat fields same as mainform
				subformidx = 0;
				while (subformidx < subFormFields.length) {
					if (subFormFields[subformidx].hasOwnProperty("footerlist")) {
						tmprowdata = createInputList(entity, [subFormFields[subformidx].footerlist], tmpformvariables, false);
						footerinputlist.push(tmprowdata);
					} else {
						footerinputlist.push([]);
					}
					subformidx++;
				}
			}
			tmprowdata = createInputList(entity, formFields, tmpformvariables, false);

			setControlkey(""+Date.now())
			setReferenceSubList(tmpreferencesubform);
			setInputList(tmprowdata);
			setInputFooterList(footerinputlist);
			setInputSubList(subforminputlist);
			setFormVariables(tmpformvariables);
			setInputdirty(false);
			setSubmitmode(false);
			setPkid(documentid);
			setMessage("");
			setLoading(false);
		}
		return () => { isMounted = false };
	}, [documentid, formData, formFields, entity, subFormFields, generateFormVariables])

	const removePicker = (entry, fieldid) => {
		// Create blank picklist selection and treat as if selected
		const tmptablename = webappsAPI.getTablefromDBfield(entry.field).toLowerCase();
		const pickerfields = entry.hasOwnProperty("pickerfields")? entry.pickerfields: [
			{"label":"Name", "dbfield": tmptablename+"_name", "type": "text", "filtertype": "textbox"},
		];
		var tmpstr = "";
		var tmpidx = 0;
		var emptyrow = {};

		emptyrow[tmptablename+"_id"] = 0;

		while (tmpidx < pickerfields.length) {
			if (pickerfields[tmpidx].type === "integer" || pickerfields[tmpidx].type === "numeric" || pickerfields[tmpidx].type === "currency") {
				emptyrow[pickerfields[tmpidx].dbfield] = 0;
			} else {
				emptyrow[pickerfields[tmpidx].dbfield] = "";
			}
			// Foreign table
			tmpstr = webappsAPI.getTablefromDBfield(pickerfields[tmpidx].dbfield).toLowerCase();
			if (tmpstr !== tmptablename) {
				emptyrow[tmpstr+"_id"] = 0;
			}
			tmpidx++;
		}

		handlePickerDone([emptyrow], fieldid);
	}

	const showPicker = (entry, fieldid) => {
		if (typeof customPicker !== undefined) {
			customPicker(pkid, inputList, inputSubList, inputFooterList, entry, fieldid, function(customentry) {
				setPickerinfo({
					"entity":webappsAPI.getTablefromDBfield(customentry.field),
					"label":customentry.label,
					"datadestination": fieldid,
					"fields":customentry.hasOwnProperty("pickerfields")? customentry.pickerfields: [],
					"filters":customentry.hasOwnProperty("pickerfilters")? customentry.pickerfilters: []
				});
			});
			return;
		}
		setPickerinfo({
			"entity":webappsAPI.getTablefromDBfield(entry.field),
			"label":entry.label,
			"datadestination": fieldid,
			"fields":entry.hasOwnProperty("pickerfields")? entry.pickerfields: [],
			"filters":entry.hasOwnProperty("pickerfilters")? entry.pickerfilters: []
		});
	}

	const handlePickerDone = (picklist, pickdest) => {
		if (picklist.length > 0) {
			if (pickdest.indexOf("_") < 1) {
				// Main Form Pick
				const tmpvariables = updateFormVariables(picklist[0], true);
				const newformdata = setPickerData(inputList, picklist[0]);

				updateFormFormulaValues(newformdata, inputFooterList, tmpvariables);
			} else {
				const deststat = pickdest.split("_");
				if (deststat[0] === "subtotal") {
					// Footer Pick
					const tmpfootervariables = updateFormVariables(picklist[0], true);
					const footeridx = parseInt(deststat[1], 10);
					var newfooterlist = [];
					var curfooteridx = 0;
					while (curfooteridx < inputFooterList.length) {
						if (curfooteridx === footeridx) {
							newfooterlist.push(setPickerData(inputFooterList[curfooteridx], picklist[0]));
						} else {
							newfooterlist.push(inputFooterList[curfooteridx]);
						}
						curfooteridx++;
					}
					updateFormFormulaValues(inputList, newfooterlist, tmpfootervariables);
					return;
				} else {
					const subformidx = parseInt(deststat[0], 10);
					const startrowidx = parseInt(deststat[1], 10);
					var tmpsubformdata = [];
					var outputidx = 0;
					var output = [];
					var picklistidx = 0;

					// Copy initial rows
					var rowidx = 0;
					while (rowidx < startrowidx) {
						tmpsubformdata.push(inputSubList[subformidx][rowidx]);
						rowidx++;
					}
					// Apply picker results
					while (picklistidx < picklist.length) {
						if (rowidx < inputSubList[subformidx].length) {
							if (inputSubList[subformidx][rowidx][0].value+""==="1") {
								tmpsubformdata.push(JSON.parse(JSON.stringify(inputSubList[subformidx][rowidx])));
							} else {
								tmpsubformdata.push(updateSubformRowFormulaValues(setPickerData(inputSubList[subformidx][rowidx], picklist[picklistidx])));
							}
						} else {
							tmpsubformdata.push(updateSubformRowFormulaValues(setPickerData(referenceSubList[subformidx], picklist[picklistidx])));
						}
						picklistidx++;
						rowidx++;
					}
					// Copy other/unaffected rows
					while (rowidx < inputSubList[subformidx].length) {
						tmpsubformdata.push(inputSubList[subformidx][rowidx]);
						rowidx++;
					}
					// Rebuild/Copy back other subforms
					while (outputidx < inputSubList.length) {
						if (outputidx === subformidx) {
							output.push(tmpsubformdata);
						} else {
							output.push(inputSubList[outputidx]);
						}
						outputidx++;
					}
					setInputSubList(output);
					updateFormFormulaValues(inputList, inputFooterList, updateSubformTotals(tmpsubformdata));
				} // Subform Picks
			}
			setFormModified();
		}
		setPickerinfo(EMPTY_PICKER);
	}

	const handleInputChange = (newvalue, type, fieldid) => {
		const finalvalue = getFinalInputValue(newvalue, type);
		if(fieldid.indexOf("_") > 0) {
			//console.log(fieldid, type, newvalue)
			const sublistparam = fieldid.split("_");
			if (sublistparam[0] === "subtotal") {
				// Footer
				var footervariableparam = {};
				var footerlist = [...inputFooterList];
				const footerlistidx = parseInt(sublistparam[1], 10);
				const fieldidx = parseInt(sublistparam[2], 10);
				footerlist[footerlistidx][fieldidx].value = finalvalue;
				footerlist[footerlistidx][fieldidx].modified = true;

				footervariableparam[footerlist[footerlistidx][fieldidx].field] = finalvalue;

				updateFormFormulaValues(inputList, footerlist, updateFormVariables(footervariableparam, false));
			} else {
				if (sublistparam.length < 3) {
					// Invalid
					return;
				}
				const sublistidx = parseInt(sublistparam[0], 10);
				const rowidx = parseInt(sublistparam[1], 10);
				const colidx = parseInt(sublistparam[2], 10);
				var deleted = false;
				if (colidx === 0 && finalvalue === "1") {
					// Delete new row (not in DB yet)
					if (inputSubList[sublistidx][rowidx][1].value === "0") {
						deleted = true;
					}
				}
				if (deleted) {
					var newlist = [...inputSubList];
					newlist[sublistidx].splice(rowidx,1);
					updateFormFormulaValues(inputList, inputFooterList, updateSubformTotals(newlist[sublistidx]));
					setInputSubList(newlist);

				} else {
					var sublist = [...inputSubList];
					sublist[sublistidx][rowidx][colidx].value = finalvalue;
					sublist[sublistidx][rowidx][colidx].modified = true;
					updateSubformRowFormulaValues(sublist[sublistidx][rowidx]);
					updateFormFormulaValues(inputList, inputFooterList, updateSubformTotals(sublist[sublistidx]));
					setInputSubList(sublist);
				}
			}
		} else {
			const index = parseInt(fieldid, 10);
			var variableparam = {};
			var list = [...inputList];
			//console.log(type,index, newvalue);
			list[index].value = finalvalue;
			list[index].modified = true;

			variableparam[list[index].field] = finalvalue;

			updateFormFormulaValues(list, inputFooterList, updateFormVariables(variableparam, false));
		}
		setFormModified();
	};

	const handleAddRow = (formidx) => {
		if (formidx < referenceSubList.length) {
			var tmpsublist = [...inputSubList];
			tmpsublist[formidx].push(JSON.parse(JSON.stringify(referenceSubList[formidx])));
			setFormModified();
		}
	}


	function updateFormVariables(newdata, checkexists)
	{
		var tmpvariables = JSON.parse(JSON.stringify(formvariables));
		Object.keys(newdata).forEach(function(tmpfield) {
			if (checkexists===false || tmpvariables.hasOwnProperty(tmpfield)) {
				tmpvariables[tmpfield] = newdata[tmpfield];
			}
		});
		setFormVariables(tmpvariables);
		return tmpvariables;
	}

	function updateInputListFormulaValues(inputlist, inputvariables, othervariables)
	{
		var idx = 0;
		var tmpdbfield = "";
		var isformula = false;
		var tmpval = 0;

		idx = 0;
		while (idx < inputlist.length) {
			tmpdbfield =  inputlist[idx].field;
			isformula = false;
			if (tmpdbfield.charAt(0) === "#") {
				tmpdbfield=tmpdbfield.substring(1);
			}
			if (webappsAPI.isDBField(tmpdbfield) === false) {
				if (tmpdbfield !== "markdelete") {
					isformula=true;
				}
			}
			if (isformula === true) {
				tmpval = webappsAPI.evalFormulaField(tmpdbfield, inputvariables, othervariables);
				if (tmpval !== inputlist[idx].value) {
					inputlist[idx].value = tmpval;
					inputlist[idx].modified = true;
				}
			}
			idx++;
		}
		return inputlist;
	}

	function updateFormFormulaValues(newforminputlist, newfooterinputlist, newformvariables)
	{
		var outfooterlist = [];
		var curinputlist = [];
		var idx = 0;
		while (idx < newfooterinputlist.length) {
			curinputlist = updateInputListFormulaValues(newfooterinputlist[idx], newformvariables, {});
			outfooterlist.push(curinputlist);
			idx++;
		}

		setInputList(updateInputListFormulaValues(newforminputlist, newformvariables, {}));
		setInputFooterList(outfooterlist);
	}

	function updateSubformRowFormulaValues(tmpformfields)
	{
		var colidx = 0;
		var tmpdbfield;
		var rowvariables = {};

		// Get Row Vairables
		colidx = 0;
		while (colidx < tmpformfields.length) {
			tmpdbfield = tmpformfields[colidx].field;
			if (tmpdbfield.charAt(0) === "#") {
				tmpdbfield=tmpdbfield.substring(1);
			}
			if (webappsAPI.isDBField(tmpdbfield) === true) {
				rowvariables[tmpdbfield]=tmpformfields[colidx].value;
			}
			colidx++;
		}

		return updateInputListFormulaValues(tmpformfields, rowvariables, formvariables);
	}

	function updateSubformTotals(subforminputlist)
	{
		var tmpformvariables={};
		var tmpidx = 0;
		while (tmpidx < subforminputlist.length) {
			tmpformvariables = generateFormVariables(tmpformvariables, subforminputlist[tmpidx], false);
			tmpidx++;
		}
		if (tmpidx > 0) {
			return updateFormVariables(tmpformvariables, false);
		}
		return formvariables;
	}


	function setFormModified()
	{
		setInputdirty(true);
		setMessage("");
		setControlboxkey(""+Date.now());
	}

	function setPickerData(sourcerow, pickerdata)
	{
		var destrow = JSON.parse(JSON.stringify(sourcerow));
		var listidx = 0;
		var destfield = "";
		while (listidx < destrow.length) {
			destfield = destrow[listidx].field;
			if (destfield.charAt(0)==="#") {
				destfield = destfield.substring(1);
			}
			if (pickerdata.hasOwnProperty(destfield)) {
				destrow[listidx].value = pickerdata[destfield];
				destrow[listidx].modified = true;
			}
			listidx++;
		}
		return destrow;
	}

	function getFinalInputValue(newvalue, type)
	{
		// Needs to allow tailing spaces for editng
		if (type === "integer" || type === "checkbox") {
			return formatTools.filterInteger(newvalue);
		} else if (type === "numeric" || type === "currency") {
			return formatTools.filterNumeric(newvalue);
		}
		return newvalue;
	}

	function abandonForm()
	{
		setPkid(0);
		setInputdirty(false);
		setSubmitmode(false);
		setMessage("");
		setLoading(false);
		if (typeof handleCancel !== "undefined") {
			handleCancel()
		}
	}
	function defaultHandleConfirm(actionid, proceed, title, subtitle)
	{
		setMessage("");
		if (actionid!=="cancel") {
			setSubmitmode(proceed);
			setSubmitaction(actionid);
			setSubmittitle(title);
			setSubmitsubtitle(subtitle);
		}
	}

	function defaultHandleAction(actionid)
	{
		if (actionid==="cancel") {
			abandonForm();
		} else if (actionid==="print") {
			if (window) {
				window.print();
			}
			setTimeout(()=>{
				// Exit read-only/print mode
				defaultHandleConfirm(actionid, false, "", "");
			}, 100);
		} else if (actionid==="save") {
			// 2022-09-09 Make submit confirm at the end of the form
			defaultHandleConfirm(actionid, true, "Confirm Submission", "Proceeding will effect permanent changes");
			//submitForm();

			// Need timeout to scroll
			setTimeout(()=>{
				headerElement.current.scrollIntoView({
					behavior: 'smooth'
				});
			}, 50);
		} else if (actionid==="delete") {
			deleteForm();
		}
	}

	function defaultValidateAction(actionid)
	{
		if (actionid === "delete") {
			// Don't delete if there are changes
			if (inputdirty) {
				setMessage("Please submit changes first");
				return false;
			}
		} else if (actionid!=="cancel") {
			return validateForm();
		}
		return true;
	}

	function submitFormResponseHandler(response)
	{
		if (response.status === "OK") {
			setMessage("Saved");
			setPkid(response.documentid);
			setInputdirty(false);
			if (typeof handleReload !== "undefined") {
				handleReload(response.documentid);
			}
		} else {
			//setMessage(JSON.stringify(response));
			if (response.hasOwnProperty("message")) {
				setMessage("Error: "+response.message);
			} else {
				setMessage("Error occured. Please try again");
			}
		}
		setSubmitmode(false);
		setLoading(false);
	}

	function validateRow(currow, suffix)
	{
		var hasdata = false;
		var idx = 0;
		var tmpval = "";
		while (idx < currow.length) {
			hasdata = false;
			if (currow[idx].value !== null && currow[idx].mode !== "readonly") {
				tmpval = getFinalInputValue(currow[idx].value, currow[idx].input);
				if (tmpval.length > 0) {
					hasdata = true;
				}
			}
			if (hasdata === false && (currow[idx].mode === "unique"||currow[idx].mode === "required")) {
				setMessage(currow[idx].label+" is required"+suffix);
				return false;
			}
			idx++;
		}
		return true;
	}

	function validateForm() {

		if (validateRow(inputList, "") === false) {
			return false;
		}
		var subformidx = 0;
		var rowidx = 0;

		while (subformidx < subFormFields.length) {
			rowidx = 0;
			while (rowidx < inputSubList[subformidx].length) {
				if (validateRow(inputSubList[subformidx][rowidx], " for "+subFormFields[subformidx].label+" row "+(rowidx+1)) === false) {
					return false;
				}
				rowidx++;
			}
			if (rowidx < parseInt(subFormFields[subformidx].minrow, 10)) {
				setMessage("Please provide at least "+subFormFields[subformidx].minrow+" "+subFormFields[subformidx].label);
				return false;
			}
			if (validateRow(inputFooterList[subformidx], "") === false) {
				return false;
			}
			subformidx++;
		}
		if (typeof customValidateValues !== "undefined") {
			 const customres = customValidateValues(inputList, inputSubList, inputFooterList);
			 if (customres.hasOwnProperty("status")) {
				 if (customres.status !== "OK") {
					 if (customres.hasOwnProperty("message")) {
						setMessage(customres.message);
					 } else {
						setMessage("Invalid value(s) for submission");
					 }
					 return false;
				 }
			 } else {
				setMessage("Error during submission");
			 }

		}
		return true;
	}

	function deleteForm() {

		setLoading(true);
		setMessage("Please wait...");

		var payloadlist = [];
		var subformpayloadlist = [];
		var params = {"documentid": pkid};
		var idx = 0;
		var rowidx = 0;
		var deletedatactr = 0;

		deletedatactr += getDeleteRowData(entity, params, payloadlist, inputList);

		// Footer Fields
		idx = 0;
		while (idx < inputFooterList.length) {
			deletedatactr += getDeleteRowData(entity, params, payloadlist, inputFooterList[idx]);
			idx++;
		}

		params.subform = [];

		// Sub forms
		idx = 0;
		while (idx < inputSubList.length) {
			// Subform Param values
			params.subform.push({table:subFormFields[idx].table, rowdata:[]});
			subformpayloadlist.push([]);

			rowidx = 0;
			while (rowidx < inputSubList[idx].length) {
				params.subform[idx].rowdata.push({});
				subformpayloadlist[idx].push([]);
				deletedatactr += getDeleteRowData(subFormFields[idx].table, params.subform[idx].rowdata[rowidx], subformpayloadlist[idx][rowidx], inputSubList[idx][rowidx]);

				rowidx++;
			}
			idx++;
		}
		var subtablelist = [];
		idx = 0;
		while (idx < subFormFields.length) {
			subtablelist.push(subFormFields[idx].table);
			idx++;
		}

		//setLoading(false);
		//return;

		if (typeof customDelete !== "undefined") {
			customDelete(entity, pkid, payloadlist, token, subtablelist, abandonForm);
			return;
		} else if (deletedatactr <= 0) {
			// No files to delete
			webappsAPI.deleteRecord(entity, pkid, token, subtablelist).then(response => {
				if (typeof customDeleteDone !== "undefined") {
					customDeleteDone(entity, pkid, payloadlist, token, subtablelist, abandonForm);
				}
			});
			return;
		}

		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(payloadresponse) {
			webappsAPI.deleteRecord(entity, pkid, token, subtablelist).then(payloaddeleteresponse => {
				if (typeof customDeleteDone !== "undefined") {
					customDeleteDone(entity, pkid, payloadlist, token, subtablelist, abandonForm);
				}
			});
		});
	} // deleteForm

	function submitValidated(params, payloadlist, subformpayloadlist)
	{
		//submitFormResponseHandler({status:"Error", message: "Debug"}); return;
		//console.log(params, payloadlist, subformpayloadlist);
		if (typeof customSubmit !== "undefined") {
			customSubmit(entity, params, payloadlist, subformpayloadlist, token, submitFormResponseHandler);
		} else {
			webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(payloadresponse) {
				submitFormResponseHandler(payloadresponse);
			});
		}
	}

	function submitForm() {
		/*
		// 2022-09-09 Already validated before proceeding
		if (validateForm() === false) {
			return;
		}
		*/
		setMessage("");
		setLoading(true);

		const curtimestamp = formatTools.getDateStr();

		var payloadlist = [];
		var tmpfilterlist = [];
		var subformfilterlist = [];
		var params = {};
		var subformpayloadlist = [];
		var idx = 0;
		var rowidx = 0;
		var submitdatactr = 0;
		var deleterowlist = [];

		submitdatactr += getSubmitRowData(entity, tmpfilterlist, params, payloadlist, inputList, curtimestamp);

		// Footer Fields
		idx = 0;
		while (idx < inputFooterList.length) {
			submitdatactr += getSubmitRowData(entity, tmpfilterlist, params, payloadlist, inputFooterList[idx], curtimestamp);
			idx++;
		}

		params.subform = [];
		params.deleterow = [];

		// Row Subtotals and payload
		idx = 0;
		while (idx < inputSubList.length) {
			deleterowlist = [];
			if (subFormFields[idx].hasOwnProperty("destfield") && subFormFields[idx].hasOwnProperty("subtotalid")) {
				if (formvariables.hasOwnProperty(subFormFields[idx].subtotalid)) {
					params[subFormFields[idx].destfield] = getSubmitValue(subFormFields[idx].input, formvariables[subFormFields[idx].subtotalid]);
				}
			}
			// Param values
			params.subform.push({table:subFormFields[idx].table, rowdata:[], deletedata:[]});

			// Payload columns
			subformpayloadlist.push([]);


			rowidx = 0;
			while (rowidx < inputSubList[idx].length) {
				subformfilterlist = []; // TODO: Check Uniqueness within subform
				params.subform[idx].rowdata.push({});
				subformpayloadlist[idx].push([]);

				if (inputSubList[idx][rowidx][0].value+"" !== "0") {
					// Row for deletion, add row pkid at idx 1
					deleterowlist.push(inputSubList[idx][rowidx][1].value+"");
					submitdatactr++;
					submitdatactr += getDeleteRowData(subFormFields[idx].table, params.subform[idx].rowdata[rowidx], subformpayloadlist[idx][rowidx], inputSubList[idx][rowidx]);
				} else {
					// Addition of row is considered edit
					submitdatactr++;
					submitdatactr += getSubmitRowData(subFormFields[idx].table, subformfilterlist, params.subform[idx].rowdata[rowidx], subformpayloadlist[idx][rowidx], inputSubList[idx][rowidx], curtimestamp);
				}
				rowidx++;
			}
			if (deleterowlist.length > 0) {
				params.deleterow.push({table:subFormFields[idx].table, idlist: deleterowlist});
			}
			idx++;
		}

		//console.log(tmpfilterlist,params, payloadlist, subformpayloadlist);

		if (submitdatactr < 1) {
			setMessage("No changes detected");
			setLoading(false);
			return;
		}

		//setLoading(false);
		//return;

		if (pkid > 0) {
			params.documentid = pkid;
			if (tmpfilterlist.length > 0) {
				tmpfilterlist.push ({
					field: entity.toLowerCase()+"_id",
					operation: "<>",
					value: pkid
				});
			}
		}
		setMessage("Please wait...");

		if (tmpfilterlist.length > 0) {
			const apiparam = {
				"dbfieldlist":[entity.toLowerCase()+"_id"],
				"countfield":entity.toUpperCase()+"."+entity.toLowerCase()+"_id",
				"filters":[tmpfilterlist]
			};

			webappsAPI.loadData(entity, apiparam, token).then(checkresponse => {
				if (checkresponse.status === "OK") {
					if (checkresponse.count > 0) {
						defaultHandleConfirm("save", false, "", "");
						setMessage("ERROR: Duplicate entry");
						setLoading(false);
					} else {
						submitValidated(params, payloadlist, subformpayloadlist);
					}
				} else {
					defaultHandleConfirm("save", false, "", "");
					setMessage("Unknown Error: Please try again");
					setLoading(false);
				}
			});

		} else {
			submitValidated(params, payloadlist, subformpayloadlist);
		}
	}

	function getDeleteRowData(tablename, curparams, curpayloadlist, inputrow)
	{
		const checkprefix = tablename.toLowerCase()+"_";
		var deletedatactr = 0;
		var tmpdbfield = "";
		var filedataprefix = "";
		var uploadtype = "";
		const deletedata = "0|";

		var idx = 0;

		while (idx < inputrow.length) {
			tmpdbfield = inputrow[idx].field;
			if (tmpdbfield.charAt(0) === "#") {
				tmpdbfield = tmpdbfield.substring(1);
			} else if (tmpdbfield.indexOf(checkprefix) !== 0) {
				if (tmpdbfield.indexOf("_id") !== (tmpdbfield.length - 3)) {
					// Don't pass picker fields, unless ID
					idx++;
					continue;
				}
			}

			if (inputrow[idx].input === "file" || inputrow[idx].input === "image") {
				// Signal delete of S3 files
				filedataprefix = inputrow[idx].value.substring(0,1);
				if (filedataprefix === "s" || filedataprefix === "h") {
					uploadtype = formatTools.defaultstr(inputrow[idx], "uploadtype", "");
					if (uploadtype !== "") {
						curpayloadlist.push(createPayloadEntry(
							uploadtype,
							formatTools.defaultstr(inputrow[idx], "filehost", ""),
							formatTools.defaultstr(inputrow[idx], "filelocation", ""),
							tmpdbfield,
							deletedata
						));
					} else {
						curparams[tmpdbfield] = "";
					}
					deletedatactr++;
					tmpdbfield = "";
				}
			}
			if (tmpdbfield !== "") {
				delete curparams[tmpdbfield];
			}
			idx++;
		} // while fields

		// Check if subform, include row docid for file deletion
		if (inputrow.length > 0) {
			if (inputrow[0].field === "markdelete" && deletedatactr > 0) {
				// Submit row pkid if existing subform row
				if (inputrow[1].value+"" !== "0") {
					curparams[inputrow[1].field] = 	inputrow[1].value;
				}
			}
		}

		return deletedatactr;
	} // getDeleteRowData

	function getSubmitRowData(tablename, curfilterlist, curparams, curpayloadlist, inputrow, curtimestamp)
	{
		// Edits parameters
		const checkprefix = tablename.toLowerCase()+"_";
		var submitdatactr = 0;
		var tmpoperation = "";
		var tmpval = "";
		var tmpdbfield = "";
		var tmpadded = false;
		var filedataprefix = "";
		var uploadtype = "";
		var editable = false;
		var subform = false;
		var subformpkid = 0;
		var fileoutformat = "";

		var idx = 0;
		const numericinputlist = ["integer", "currency", "numeric"];

		// Check if subform
		if (inputrow.length > 0) {
			if (inputrow[0].field === "markdelete") {
				subform = true;
				// Submit row pkid if existing subform row
				if (inputrow[1].value+"" !== "0") {
					curparams[inputrow[1].field] = 	inputrow[1].value;
					subformpkid = parseInt(inputrow[1].value, 10);
				}
			}
		}

		while (idx < inputrow.length) {
			tmpadded = false;
			tmpdbfield = inputrow[idx].field;
			tmpval = formatTools.defaultstr(inputrow[idx], "destfield", "");
			if (tmpval !== "") {
				// Has Save-As setting
				tmpdbfield = tmpval;
				editable = true;
			} else if (tmpdbfield.charAt(0) === "#") {
				tmpdbfield = tmpdbfield.substring(1);
				editable = true;
			} else if (tmpdbfield.indexOf(checkprefix) !== 0) {
				if (tmpdbfield.indexOf("_id") !== (tmpdbfield.length - 3)) {
					// Don't pass picker fields, unless ID
					idx++;
					continue;
				}
				editable = true;
			} else {
				editable = (inputrow[idx].mode !== "readonly");
			}
			// Modified data or new blank row
			if (inputrow[idx].value !== null && editable && (inputrow[idx].modified || (subform && subformpkid===0))) {
				tmpval = "";
				tmpoperation = "=";
				if (inputrow[idx].input === "file" || inputrow[idx].input === "image") {
					// If already migrated to s3, don't update
					filedataprefix = inputrow[idx].value.substring(0,1);
					if (filedataprefix !== "s" && filedataprefix !== "h") {
						uploadtype = formatTools.defaultstr(inputrow[idx], "uploadtype", "");
						fileoutformat = formatTools.defaultstr(inputrow[idx], "imageformat", "");
						if (uploadtype !== "") {
							tmpadded = true;
							curpayloadlist.push(createPayloadEntry(
								uploadtype,
								formatTools.defaultstr(inputrow[idx], "filehost", ""),
								formatTools.defaultstr(inputrow[idx], "filelocation", ""),
								tmpdbfield,
								inputrow[idx].value,
								fileoutformat
							));
							if (subformpkid === 0 && subform) {
								// Initialized payload field to blank to trigger submission
								curparams[tmpdbfield] = "";
							} else {
								// Don't submit if payload field to avoid overwrite
								delete curparams[tmpdbfield];
							}
						} else {
							tmpadded = true;
							if (filedataprefix === "0") {
								curparams[tmpdbfield] = "";
							} else {
								curparams[tmpdbfield] = inputrow[idx].value;
							}
						}
					}
				} else {
					tmpval = getSubmitValue(inputrow[idx].input, inputrow[idx].value);
					if (!numericinputlist.includes(inputrow[idx].input)) {
						tmpoperation = " like ";
					}
					curparams[tmpdbfield] = tmpval;
					tmpadded = true;
				}
				if (tmpadded) {
					submitdatactr++;
				}
				if (inputrow[idx].mode === "unique") {
					// This should have valid value at this point
					curfilterlist.push ({
						field: tmpdbfield,
						operation: tmpoperation,
						value: tmpval
					});
				}
			}
			// Timestamp values
			if (inputrow[idx].input === "createtime") {
				if (pkid === 0) {
					curparams[tmpdbfield] = curtimestamp;
				}
			} else if (inputrow[idx].input === "updatetime") {
				curparams[tmpdbfield] = curtimestamp;
			}
			idx++;
		} // while fields
		return submitdatactr;
	} // getSubmitRowData

	function getSubmitValue(type, value)
	{
		if (type ==="integer") {
			return formatTools.integer(value);
		} else if (type ==="currency" || type ==="numeric") {
			return formatTools.numeric(value);
		}
		return formatTools.trimstr(value);
	}

	function createPayloadEntry(uploadtype, filehost, filehostlocation, dbfield, data, outformat = "") {
		return {
			"type": uploadtype,
			"host": (filehost !== "" ? filehost:DEFAULT_FILEHOST),
			"location":(filehostlocation !== ""? filehostlocation:DEFAULT_FILELOCATION),
			"payloadfield": dbfield,
			"data": data,
			"outformat": outformat
		};
	}

	function submitCancelClick(e) {
		if (e) {
			e.preventDefault();
		}
		defaultHandleConfirm("save", false,  "", "");
	}

	function submitConfirmClick(e) {
		if (e) {
			e.preventDefault();
		}
		submitForm();
	}

	function toggleHistory(e) {
		if (e) {
			e.preventDefault();
		}
		setDisplayhistory(!displayhistory);
	}

	return (<>{pickerinfo.entity === "" ?
		<div className={tablescroll.headerfooter +" "+styles.editscrollholder}>
			<table>
				<tbody>
					<tr ref={headerElement}>
						<td className={styles.editscrollrow}>
							<h2 className={styles.pagetitle+" text-centered noprint"}>
								{initializing?
									"Please Wait..."
								: (submitmode?
									submittitle
								:
									(pkid > 0? "Edit "+pagetitle:"New "+pagetitle)
								)}
							</h2>
							<div className={styles.editfieldcontainer}>
								{ inputList.map((entry, idx) => {
										if (typeof customFieldFragment !== "undefined") {
											const tmpfragment = customFieldFragment(inputList, idx);
											if (tmpfragment !== null) {
												return tmpfragment
											}
										}
										var finalentrydata = entry;

										if (typeof customFieldInfo !== "undefined") {
											finalentrydata = customFieldInfo(inputList, idx);
										}

										if (finalentrydata.field === entity+"_id" || finalentrydata.input === "hidden") {
											return <input type={"hidden"} name={finalentrydata.field} value={finalentrydata.value} />
										}
										return <>
											<FormField
												fieldid={""+idx}
												fielddetails={finalentrydata}
												initializing={initializing}
												disabled={disabled || loading}
												viewmode={submitmode}
												controlkey={controlkey}
												handleChange={handleInputChange}
												showPicker={showPicker}
												removePicker={removePicker}
												token={token}
												pkid={pkid}
											/>
										</>
									})}
							</div>
						</td>
					</tr>
					{subFormFields.map((subform, subformidx) => {
						return <tr key={"subformrow"+entity+subform.table+subformidx}>
							<td>
								<SubForm
										key={"subform"+entity+subform.table+subformidx}
										pkid={pkid}
										token={token}
										viewmode={submitmode}
										handleInputChange={handleInputChange}
										handleAddRow={handleAddRow}
										showPicker={showPicker}
										removePicker={removePicker}
										initializing={initializing}
										disabled={disabled || loading}
										forminfo={subform}
										forminputlist={inputSubList[subformidx]}
										formfooterlist={inputFooterList[subformidx]}
										formidx={subformidx}
										formvariables={formvariables}
										customFieldInfo={customFieldInfo}
									/>
							</td>
						</tr>
					})}
					{(submitmode && submitaction === "save") && <tr>
						<td className="text-centered">
							<div>
							{loading?
								<div>
									Please wait...
								</div>
							:
							<>
								<div className={styles.editcontrolboxmessage+" "+styles.editcontrolboxmessagereadonly}>
									{submittitle}
								</div>
								<div>
									{submitsubtitle}
								</div>
							</>
							}
							</div>
							<button title={"Cancel"} disabled={disabled||loading} className={"iconbutton iconbutton-large"} onClick={submitCancelClick} >
								<img src={imgclose} alt="Cancel"/>
							</button>
							<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
							<button title={"OK"} disabled={disabled||loading} className={"iconbutton iconbutton-large"} onClick={submitConfirmClick}>
								<img src={imgcheck} alt={"Proceed"} />
							</button>

						</td>
					</tr>}
					{(formData.hasOwnProperty("history") && initializing === false) && <>
						{formData.history.length > 0 && <>
							<tr>
								<td className={styles.edithistoryholder}>
									<div className={styles.edithistoryprimary}>
										<div className={displayhistory?styles.edithistorylisttitle+" font-size-medium":""} >{displayhistory?<strong>History</strong>:(formatTools.datetimestr(formData.history[0].dbactivity_date)+": "+(formData.history[0].dbactivity_source))}</div>
										{formData.history.length > 1 && <img src={displayhistory?imgexpandless:imgexpandmore} alt="More/Less" title={displayhistory?"Less":"More"} onClick={toggleHistory} />}
									</div>
									{(displayhistory === true && formData.history.length > 1) && <div className={styles.edithistorylist}>
										{formData.history.map((historyitem, historyitemidx)=>{
											return <div>{formatTools.datetimestr(historyitem.dbactivity_date)+": "+(historyitem.dbactivity_source)}</div>
										})}
									</div>}
								</td>
							</tr>
						</>}
					</>}
				</tbody>
				{(submitmode === false || submitaction !== "save") &&
					<tfoot className="noprint">
						<tr>
							<td className={styles.editcontrolbox+" text-centered"}>
								<EditControlBox
									key={controlboxkey}
									message={message}
									enabledelete={pkid > 0 && allowdelete}
									enableaction={inputdirty}
									disabled={disabled||loading}
									validateAction={defaultValidateAction}
									handleActionconfirm={defaultHandleConfirm}
									handleAction={defaultHandleAction}
									clearMessage={()=>{setMessage("")}}
									actionlist={[
										{
											"text": "Back",
											"title": "Back",
											"abandonbutton": true,
											"key": "cancel",
											"confirmtext": "Abandon Changes"
										},
										{
											"text": "Delete",
											"title": "Delete",
											"deletebutton": true,
											"key": "delete",
											"confirmtext": "Delete Record",
											"confirmsubtext": "This action cannot be undone"
										},
										{
											"text": "Print",
											"title": "Print",
											"key": "print",
											"confirmtext": "Printing..."
										},
										{
											"text": "Save",
											"title": "Save",
											"actionbutton": true,
											"key": "save"
										},
									]}
								/>
							</td>
						</tr>
					</tfoot>
				}
			</table>
		</div>
	:
		<WebappPicker
			token={token}
			userparam={{}}
			pagetitle={pickerinfo.label}
			entity={pickerinfo.entity}
			searchFields={pickerinfo.fields}
			userfilter={pickerinfo.filters}
			multiselect={pickerinfo.datadestination.indexOf("_")>0?true:false}
			datadestination={pickerinfo.datadestination}
			dataHandler={handlePickerDone}

		/>
	}</>
	)
}


WebappEdit.propTypes = {
	initializing: PropTypes.bool,
	allowdelete: PropTypes.bool,
	pagetitle: PropTypes.string,
	subFormFields: PropTypes.array
}


WebappEdit.defaultProps = {
	initializing: false,
	allowdelete: false,
	pagetitle: "",
	subFormFields: []
}

export default WebappEdit
