import React, { useState } from 'react';
import PropTypes from "prop-types"

import * as styles from "./floattextbox.module.css"

import imgcheck from "../../images/buttons/done.png"
import imgclose from "../../images/buttons/close.png"

const ControlFloatTextbox = ({displaytext, defaultvalue, handleChange, handleCancel, disabled}) => {
	const [textvalue, setTextvalue] = useState(defaultvalue);

	const handleInputChange = (e) => {
		setTextvalue(e.target.value);
	};

	return(
		<div className={styles.floattextboxholder+" fullborder"}>
			{displaytext!=="" &&
				<div className={styles.floattextboxtitle+" font-style-bold"}>{displaytext}</div>
			}
			<input
				disabled={disabled}
				value={textvalue}
				onChange={handleInputChange}
				onBlur={handleInputChange}
			/>
			<div className={styles.floattextboxcontrolbox}>
				<button className={styles.floattextboxcontrolboxcancel+" iconbutton  iconbutton-medium"} onClick={handleCancel} title={"Cancel"} >
					<img src={imgclose} alt="Cancel"/>
				</button>
				<button className={"iconbutton iconbutton-medium"} onClick={(e)=>{handleChange(textvalue)}}  title={"Accept"}>
					<img src={imgcheck} alt="Accept" />
				</button>
			</div>
		</div>
	)
}


ControlFloatTextbox.propTypes = {
	displaytext: PropTypes.string,
	defaultvalue: PropTypes.string,
	disabled: PropTypes.bool,
}


ControlFloatTextbox.defaultProps = {
	displaytext: ``,
	defaultvalue: ``,
	disabled: false,
}


export default ControlFloatTextbox;

