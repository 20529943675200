import React, { useState } from 'react';
import PropTypes from "prop-types"


import * as styles from "../style.module.css"

import imgcheck from "../../../images/buttons/done.png"
import imgclose from "../../../images/buttons/close.png"


/*
	actionlist = [
		{
			"text": "For textbutton (w/ or w/o img)",
			"icon": image obj,
			"title": "Hover Display",
			"abandonbutton": false,
			"actionbutton": true,
			"key": "Custom key to send on press",
			"confirmtext": "",
			"subtext": ""
		}

	]

*/

const EditControlBox = ({message, actionlist, disabled, enableaction, enabledelete, validateAction, handleActionconfirm, handleAction, clearMessage}) => {
	const [confirmtext, setConfirmtext] = useState("");
	const [confirmsubtext, setConfirmsubtext] = useState("");
	const [confirmidx, setConfirmidx] = useState(-1);

	const defaultClearMessage = (e) => {
		if (e) {
			e.preventDefault();
		}
		if (typeof clearMessage !== "undefined" && !disabled) {
			clearMessage();
		}
	}

	const buttonProceed = (buttonidx) => {
		if (typeof handleAction !== "undefined" && actionlist[buttonidx].hasOwnProperty("key")) {
			handleAction(actionlist[buttonidx].key);
		}
		setConfirmidx(-1);
		setConfirmtext("");
	}

	const defaultButtonClick = (e, buttonidx) => {
		if (e) {
			e.preventDefault();
		}
		if (typeof validateAction !== undefined) {
			if(validateAction(actionlist[buttonidx].key) === false) {
				return;
			}
		}
		var confirmtext = "";
		var confirmsubtext = "";
		if (actionlist[buttonidx].hasOwnProperty("confirmtext")) {
			confirmtext = actionlist[buttonidx].confirmtext;
			if (actionlist[buttonidx].hasOwnProperty("confirmsubtext")) {
				confirmsubtext = actionlist[buttonidx].confirmsubtext;
			}
			if (actionlist[buttonidx].hasOwnProperty("abandonbutton")) {
				if (actionlist[buttonidx].abandonbutton) {
					confirmtext = "";
					if (enableaction) {
						confirmtext = actionlist[buttonidx].confirmtext;
					}
				}
			}
		}
		if (confirmtext !== "") {
			if (typeof handleActionconfirm !== "undefined") {
				handleActionconfirm(actionlist[buttonidx].key, true, confirmtext+"?", confirmsubtext);
			}
			setConfirmidx(buttonidx);
			setConfirmsubtext(confirmsubtext);
			if (actionlist[buttonidx].key === "print") {
				setConfirmtext(confirmtext+"...");
				// No more confirmation for print
				buttonProceed(buttonidx);
			} else {
				setConfirmtext(confirmtext+"?");
			}
			return;
		}
		buttonProceed(buttonidx);
	}

	const confirmCancelClick = (e) => {
		if (e) {
			e.preventDefault();
		}
		if (typeof handleActionconfirm !== "undefined" && confirmidx>=0) {
			handleActionconfirm(actionlist[confirmidx].key, false, "", "");
		}

		setConfirmtext("");
	}

	const confirmButtonClick = (e) => {
		if (e) {
			e.preventDefault();
		}
		if (confirmidx>=0) {
			buttonProceed(confirmidx);
		}
	}
	return(<>
		{confirmtext !== "" ?<>
			<div>
				<div className={styles.editcontrolboxmessage+" "+styles.editcontrolboxmessagereadonly}>
					<div>{confirmtext}</div>
				</div>
				{confirmsubtext !== "" && <div>
					{confirmsubtext}
				</div>}
			</div>
			<button title={"Cancel"} disabled={disabled} className={"iconbutton iconbutton-large"} onClick={confirmCancelClick} >
				<img src={imgclose} alt="Cancel"/>
			</button>
			<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
			<button title={"OK"} disabled={disabled} className={"iconbutton iconbutton-large"} onClick={confirmButtonClick}>
				<img src={imgcheck} alt={"OK"} />
			</button>

		</>
		:
		<>
			{message !== "" && <div>
				<div className={styles.editcontrolboxmessage} tabIndex={0} role="button" onKeyDown={(e)=>{/* Prevents warning */}} onClick={defaultClearMessage}>
					<div>{message}</div>
					{disabled === false &&
						<span className="font-size-tiny">X</span>
					}
				</div>
			</div>}
			{actionlist.map((entry, idx)=>{
				var classinfo = "iconbutton iconbutton-large";
				var btntitle = null;
				var imgtitle = "";
				var actionactivated = true;
				var actiontype = "";
				if (entry.hasOwnProperty("actionbutton")) {
					if (entry.actionbutton) {
						actionactivated = enableaction;
						actiontype = "action";
					}
				}
				if (entry.hasOwnProperty("deletebutton")) {
					if (entry.deletebutton) {
						if (enabledelete === false) {
							return <></>
						}
						actionactivated = enabledelete;
						actiontype = "delete";
					}
				}

				if (entry.hasOwnProperty("text")) {
					imgtitle = entry.text;
					classinfo = "textbutton "+styles.editcontrolboxtextbutton;
					if (actiontype !== "") {
						classinfo = classinfo + " " + styles["editcontrolboxtextbutton"+actiontype];
					}
				} else if (entry.hasOwnProperty("title")) {
					btntitle = entry.title;
					imgtitle = entry.title;
				}

				return <>
					{idx > 0 && <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
					<button title={btntitle} disabled={disabled || actionactivated === false} className={classinfo} onClick={(e)=>{defaultButtonClick(e, idx);}} >
						{entry.hasOwnProperty("text") && <span>{entry.title}</span>}
						{entry.hasOwnProperty("icon") && <img src={entry.icon} alt={imgtitle}/>}
					</button>
				</>
			})}
		</>}
	</>)
}


EditControlBox.propTypes = {
	message: PropTypes.string,
	enableaction: PropTypes.bool,
	enabledelete: PropTypes.bool,
	disabled: PropTypes.bool
}


EditControlBox.defaultProps = {
	message: ``,
	enableaction: true,
	enabledelete: true,
	disabled: false,
}


export default EditControlBox;

