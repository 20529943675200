import React, { useState } from 'react';
//export { ContentEditable, ContentEditableEvent, ContentEditableProps, DefaultEditor, DefaultEditor as default, Editor, EditorProps, EditorContext, EditorProvider, useEditorState, EditorState, HtmlEditor, BtnBold, BtnBulletList, BtnClearFormatting, BtnItalic, BtnStrikeThrough, BtnLink, BtnNumberedList, BtnRedo, BtnUnderline, BtnUndo, createButton, BtnStyles, createDropdown, Dropdown, DropDownItem, DropDownFactoryProps, DropdownProps, HtmlButton, Separator, Toolbar };

import {
	BtnBold,
	BtnItalic,
	BtnUnderline,
	BtnStrikeThrough,
	BtnBulletList,
	BtnClearFormatting,
	BtnNumberedList,
	BtnUndo,
	BtnRedo,

	createButton,
	EditorProvider,
	Toolbar,
	Editor,
	Separator
} from 'react-simple-wysiwyg';

//import Editor from 'react-simple-wysiwyg';
import PropTypes from "prop-types"


const ControlRichTextarea = ({defaultValue, handleChange, disabled}) => {
	const [textvalue, setTextvalue] = useState(typeof defaultValue == "string"?defaultValue:"");

	const handleInputChange = (e) => {
		setTextvalue(e.target.value);
		// Use paragraph instead of div
		handleChange(e.target.value.replace(/div>/g,"p>"));
	};

	const BtnAlignCenter = createButton('Align Center', '≡', 'justifyCenter');
	const BtnAlignLeft = createButton('Align Left', '⬱', 'justifyLeft');
	const BtnAlignRight = createButton('Align Right', '⇶', 'justifyRight');

	console.log(disabled);
	if (disabled) {
		return (
			<div style={{width:"100%", padding:"1vw", border:"1px solid #dddddd", backgroundColor:"#eeeeee"}} dangerouslySetInnerHTML={{ __html: textvalue }} />
		)
	}
/*
	return(
		<Editor value={textvalue} onChange={handleInputChange} />
	)
*/
	return(
		<EditorProvider>
			<Editor value={textvalue} onChange={handleInputChange}>
				<Toolbar>
					<BtnUndo/><BtnRedo/>
					<Separator />
					<BtnBold /> <BtnItalic /> <BtnUnderline /> <BtnStrikeThrough />
					<Separator />
					<BtnNumberedList /><BtnBulletList />
					<Separator />
					<BtnAlignLeft />
					<BtnAlignCenter />
					<BtnAlignRight />
				</Toolbar>
			</Editor>
		</EditorProvider>
	)
}


ControlRichTextarea.propTypes = {
	defaultvalue: PropTypes.string,
	disabled: PropTypes.bool,
}


ControlRichTextarea.defaultProps = {
	defaultvalue: ``,
	disabled: false,
}


export default ControlRichTextarea;

