import React, {useRef, useState } from "react"
import PropTypes from "prop-types"

import FileBrowser from "./filebrowser"

import * as styles from "./upload.module.css"

import imgcheck from "../../images/buttons/done.png"
import imgclose from "../../images/buttons/close.png"

/*
	sizeMode = limit, fit, fill, stretch, as-is (blank)
*/

const ImageUpload = ({children, identifier, srcurl, shownoimage, outputFormat, sizeMode, targetWidth, targetHeight, className, disabled, uploadHandler, uploadCancel, uploadStart}) => {
	const [updated, setUpdated] = useState(false);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");
	const [imgname, setImgName] = useState("");
	const [imgwidth, setImgWidth] = useState(0);
	const [imgheight, setImgHeight] = useState(0);
	const [imgerror, setImgerror] = useState(false);

	const canvasRef = useRef(null)

	const cancelUpload = (e) => {
		if (e) {
			e.preventDefault();
		}

		setImgName("")
		setUpdated(false);
		setImgWidth(0)
		setImgHeight(0)

		if (typeof uploadCancel !== "undefined") {
			uploadCancel();
		}
	}

	const uploadFile = (event) => {
		if (typeof uploadHandler !== 'undefined') {
			var fileextidx = imgname.lastIndexOf(".");
			var outfname = imgname.substring(0, fileextidx).toLowerCase().replace(/\s/g, " ").replace(/[^a-zA-Z0-9_\-. ]/g, "");
			var filetype = imgname.substring(fileextidx+1).toLowerCase();
			const canvas = canvasRef.current;
			var buffer = "";
			var bufferidx = 0;

			if (outputFormat === "png") {
				filetype = "png";
			} else if (outputFormat === "jpeg" || outputFormat === "jpg") {
				filetype = "jpeg";
			}

			if (filetype === "png") {
				filetype = "image/png";
				buffer = canvas.toDataURL(filetype);
			} else {
				filetype = "image/jpeg";
				buffer = canvas.toDataURL(filetype, 0.8);
			}
			bufferidx = buffer.indexOf(";");
			var mimetype = buffer.substring(5, bufferidx);

			// Need to check format of the output buffer
			if (mimetype === "image/jpeg") {
				outfname = outfname+".jpg";
			} else {
				outfname = outfname+"."+mimetype.substring(6);
			}

			bufferidx = buffer.indexOf(",");
			uploadHandler(identifier, outfname, buffer.substring(bufferidx+1), mimetype);
			cancelUpload(null);
		}
		setImgerror(false);

	}

	const fileSelectedHandler = (event) => {
		if (event.target.files.length > 0) {
			setLoading(true);
			setMessage("Loading...");

			const reader = new FileReader();
			const curfname = event.target.files[0].name;
			reader.onload = readevent => {
				const buffer = readevent.target.result;
				const typestartidx = buffer.indexOf(":");
				const typeendidx = buffer.indexOf("/");
				const filetype = buffer.substring(typestartidx+1, typeendidx);

				if (filetype === "image") {
					setImgName(curfname);
					var img = new Image();
					img.onload = function() {
						const canvas = canvasRef.current
						const context = canvas.getContext('2d')
						var scalefactorX=0;
						var scalefactorY=0;
						var scaleWidth = 0;
						var scaleHeight = 0;
						var canvasWidth = 0;
						var canvasHeight = 0;
						var asismode = true;

						if ((sizeMode === "limit" || sizeMode === "fit" || sizeMode === "fill") && (targetWidth>0 || targetHeight >0)) {
							asismode = false;
						} else if ((sizeMode === "stretch") && (targetWidth>0 && targetHeight >0)) {
							asismode = false;
						}

						if (asismode === true) {
							// As-is
							setImgWidth(img.width);
							setImgHeight(img.height);
							context.drawImage(img,0,0);
						} else {
							if (targetWidth > 0) {
								scalefactorX = targetWidth/img.width;
								scalefactorY = scalefactorX;
								canvasWidth = targetWidth;
							}
							if (targetHeight > 0) {
								canvasHeight = targetHeight;
								scalefactorY = targetHeight/img.height;
								if (sizeMode !== "stretch") {
									if (scalefactorX === 0
										|| (scalefactorY > scalefactorX && sizeMode === "fill")
										|| (scalefactorY < scalefactorX && (sizeMode === "fit" || sizeMode === "limit")) ) {
											scalefactorX = scalefactorY;
									} else {
										scalefactorY = scalefactorX;
									}
								}
							}
							scaleHeight = Math.floor(scalefactorY*img.height);
							scaleWidth = Math.floor(scalefactorX*img.width);
							if (canvasWidth <= 0 || sizeMode === "limit") {
								canvasWidth = scaleWidth;
							}
							if (canvasHeight <= 0 || sizeMode === "limit") {
								canvasHeight = scaleHeight;
							}
							setImgWidth(canvasWidth);
							setImgHeight(canvasHeight);
							context.drawImage(img, Math.floor((canvasWidth-scaleWidth)*0.5), Math.floor((canvasHeight-scaleHeight)*0.5), scaleWidth, scaleHeight);

						}
					}
					img.src = readevent.target.result;
					if (typeof uploadStart !== "undefined") {
						uploadStart();
					}
					setMessage("");
				} else {
					setUpdated(false);
					setMessage("Invalid image file");
				}
				setLoading(false);
			}
			setUpdated(true);
			reader.readAsDataURL(event.target.files[0]);
		}
	};

	const imageZoom = (srcurl) => {
		window.open(srcurl,"_blank");
	};

	return <div className={imgwidth === 0? className: className+" "+styles.uploadimageconfirm}>
		<div className={styles.uploadimageholder+" text-centered"}>
			{imgwidth === 0 && <>
				{(srcurl !== "" && imgerror === false) ?
					<button className={styles.zoomimageholder} onClick={(e)=>{imageZoom(srcurl);}}>
						<div className={"text-centered font-style-italic font-size-small noprint"}>(Click to Zoom)</div>
						<img
							onError={(e)=>{setImgerror(true);}}
							src={srcurl}
							alt=""
						/>
					</button>
				:
					<div style={disabled?{"textAlign":"left"}:{}}>{srcurl === ""?(shownoimage?"No Image":""):"Available for download"}</div>
				}
			</>}
			<canvas ref={canvasRef} width={imgwidth} height={imgheight} />
		</div>
		<div className={"text-centered font-size-small noprint"}>
			{(loading || disabled) ? <>{disabled===false?"Loading...":<>{children && <>{children}</>}</>}</>
			:
				<>
				{imgname !== "" && <div>{imgname}</div>}
				{message !== "" && <div className={styles.uploadcontrolboxmessage+" font-style-italic font-style-bold"}>{message}</div>}
				{updated===true ? <div  className={styles.uploadcontrolbox + " text-centered"}>
						<div className={"text-centered font-style-italic"}>(Please Confirm)</div>
						<button className={styles.uploadcontrolboxcancel+" iconbutton iconbutton-large"} onClick={cancelUpload} title={"Cancel"}>
							<img src={imgclose} alt="Cancel"/>
						</button>
						<button className={"iconbutton iconbutton-large"} onClick={uploadFile} title={"Cancel"}>
							<img src={imgcheck} alt="Accept" />
						</button>
					</div>
				:<>
					{children && <>{children}</>}
					<FileBrowser disabled={disabled} textlabel={"Choose File"} className={"textbutton"} handleFile={fileSelectedHandler} />
					{(targetWidth>0 && targetHeight>0 && (sizeMode==="fit" || sizeMode==="fill" || sizeMode==="stretch"))&&<div className={""}>{"("+(sizeMode==="fit"?"max.":"")+targetWidth+"x"+targetHeight+")"}</div>}
				</>
				}
				</>
			}

		</div>
	</div>
}


ImageUpload.propTypes = {
	disabled: PropTypes.bool,
	shownoimage: PropTypes.bool,
	identifier: PropTypes.string,
	className: PropTypes.string,
	outputFormat: PropTypes.string,
	sizeMode: PropTypes.string,
	targetWidth: PropTypes.number,
	targetHeight: PropTypes.number
};

ImageUpload.defaultProps = {
	disabled: false,
	shownoimage: true,
	identifier: "",
	className: "",
	sizeMode: "",
	targetWidth: 0,
	targetHeight: 0,
	outputFormat: ""
};


export default ImageUpload